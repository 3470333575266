import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import range from 'lodash/range';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'flex',
    height: '10px',
  },
  longDash: {
    width: '63px',
    borderRadius: '5px',
    margin: '0 4px',
    backgroundColor: theme.palette.primary.light,
  },
  shortDash: {
    width: '13px',
    borderRadius: '5px',
    margin: '0 3px',
    backgroundColor: theme.palette.extended.progressBarBackground,
  },
}));

interface DashProgressBarProps {
  steps: number;
  current: number;
}

const DashProgressBar: React.FC<DashProgressBarProps> = ({steps, current}) => {
  const classes = useStyles();

  const LongDash = () => <div className={classes.longDash} />;
  const ShortDash = () => <div className={classes.shortDash} />;

  return (
    <div className={classes.root}>
      {range(1, steps + 1).map((i) =>
        i === current ? <LongDash key={i} /> : <ShortDash key={i} />,
      )}
    </div>
  );
};

export default DashProgressBar;
