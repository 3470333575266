import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import StudyService from "../service/StudyService";
import {useAsyncState} from "../model/state/asyncState";
import {ErrorPage} from "../components/ErrorInterrupter";
import {CardDetails} from "../components/library/CardDetails";
import LibraryStudy from "../components/library/LibraryStudy";
import clsx from "clsx";
import {SearchInput} from "../components/input/SearchInput";
import {LoadingFallback} from "../components/progress/LoadingFallback";
import {NoData} from "../components/NoData";
import {HighlightText} from "../components/HighlightText";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";
import {Head, SortItem, SortOrder } from "./LibraryPage";
import { useStyles as useLibraryStyles } from "../pages/LibraryPage";

const useStyles = makeStyles((theme: Theme) => ({
  togglesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '37px',
  },
  toggles: {
    width: '321px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '20px',
    backgroundColor: 'white',
    cursor: 'pointer',
    paddingHorizontal: '1px',
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '49%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
  inactiveToggle: {},
  activeToggle: {
    margin: 'auto',
    color: 'white',
    height: '36px',
    backgroundColor: '#191919',
    borderRadius: '20px',
  },
  deckDetailsActionsRoot: {
    width: '100%',
    marginTop: '32px',
    marginBottom: '18px',
  },
  detailsMain: {
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingTop: '29px',
    paddingBottom: '33px',
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '12px',
  },
}));

const toggles = ['Review', 'Study'];

type DeckParams = {
  deckId: string;
};

export const DeckDetails: React.FC = () => {
  const classes = useLibraryStyles();
  const deckClasses = useStyles();
  const {deckId} = useParams<keyof DeckParams>() as DeckParams;
  const getState = useCallback(() => {
    return StudyService.getUserLibraryDeckCards(deckId);
  }, [deckId]);
  const state = useAsyncState(getState);
  const deckState = useAsyncState(StudyService.getUserLibraryDecks);
  const [sort, setSort] = useState<SortOrder>(SortOrder.nameForward);
  const [activeToggleIndex, setActiveToggleIndex] = useState(0);

  const [searchString, setSearchString] = useState('');

  const searchResult = useMemo(() => {
    if (!state.data) return [];
    if (typeof searchString === 'undefined' || searchString === '')
      return state.data;
    return state.data.filter((card) => {
      const id = card.id.toLowerCase();
      const name = card.name.toLowerCase();
      const content = card.content.toLowerCase();
      const search = searchString.toLowerCase();
      return (
        id.includes(search) || name.includes(search) || content.includes(search)
      );
    });
  }, [state, searchString]);

  const sortedCards = useMemo(() => {
    if (!searchResult) return [];

    const mutable = [...searchResult];

    return mutable.sort((a, b) => {
      const deckAName = a.name.toLowerCase();
      const deckBName = b.name.toLowerCase();
      switch (sort) {
        case SortOrder.nameForward:
          return deckAName > deckBName ? 1 : -1;
        default:
          return deckAName > deckBName ? -1 : 1;
      }
    });
  }, [searchResult, sort]);

  const currentDeckName = useMemo(() => {
    const result = deckState.data?.find((deck) => deck.deckId === deckId);
    if (typeof result === 'undefined') return '';
    return result.deckName;
  }, [deckId, deckState]);

  const [showCards, setShowCards] = useState<string[] | undefined>(undefined);
  const [studyCards, setStudyCards] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    document.title = 'Library Deck – Blank Slate';
  }, []);

  if (state.error) return <ErrorPage error={state.error} onReset={() => {}} />;

  return (
    <>
      {typeof showCards !== 'undefined' ? (
          <CardDetails
            cardIds={showCards}
            deckState={deckState}
            onClose={() => {
              setShowCards(undefined);
            }}
          />
        ) :
        studyCards ? (
            <LibraryStudy
              cardIds={studyCards}
              deckState={deckState}
              onClose={() => {
                setStudyCards(undefined);
              }}
            />
          ) :
          (
            <div className={classes.root}>
              <div>
                <Head title={currentDeckName} text="Click on any card to review" />
                <div className={deckClasses.togglesContainer}>
                  <div className={deckClasses.toggles}>
                    {toggles.map((toggle, index) => (
                      <div
                        key={toggle}
                        className={clsx(deckClasses.toggle, activeToggleIndex === index ? deckClasses.activeToggle : deckClasses.inactiveToggle)}
                        onClick={() => {
                          activeToggleIndex !== index && setActiveToggleIndex(index);
                        }}
                      >
                        {toggle}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={deckClasses.deckDetailsActionsRoot}>
                  <SearchInput
                    isDisabled={state.inProgress}
                    onChange={(newValue) => {
                      setSearchString(newValue);
                    }}
                    value={searchString}
                    placeholder="search"
                  />
                </div>
                <div className={deckClasses.detailsMain}>
                  <div className={classes.sort}>
                    <SortItem
                      disabled={state.inProgress}
                      onClick={() => {
                        if (sort === SortOrder.nameForward) {
                          setSort(SortOrder.nameReverse);
                        } else {
                          setSort(SortOrder.nameForward);
                        }
                      }}
                      name="Questions"
                    />
                  </div>
                  {state.inProgress ? (
                    <LoadingFallback />
                  ) : (
                    <div className={classes.items}>
                      {searchResult?.length === 0 ? (
                        <NoData />
                      ) : (
                        sortedCards?.map((card) => {
                          return (
                            <button
                              key={card.id}
                              className={classes.itemButton}
                              onClick={() => {
                                const firstIndex = state.data?.findIndex(
                                  (c) => c.id === card.id,
                                );
                                const cardsToReview = state.data?.slice(firstIndex);
                                setShowCards(cardsToReview?.map((card) => card.id));
                              }}>
                              <div className={classes.itemContainer}>
                                <div className={classes.itemText}>
                                  <HighlightText
                                    text={card.name}
                                    highlight={searchString}
                                  />
                                </div>
                              </div>
                            </button>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <button
                  className={classes.button}
                  disabled={state.inProgress}
                  onClick={
                    () => {
                      if (state.data && state.data.length > 0) {
                        activeToggleIndex === 0 ?
                          setShowCards(state.data?.map((card) => card.id)) :

                          setStudyCards(state.data?.map((card) => card.id));
                      }
                    }
                  }
                >Start</button>
              </div>
            </div>
          )}
    </>
  );
};
