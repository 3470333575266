import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/Settings.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import TextInput from '../components/input/TextInput';
import {useRecoilState, useSetRecoilState} from 'recoil';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import * as A from 'fp-ts/Array';
import {pipe} from 'fp-ts/lib/function';
import {eqNumber} from 'fp-ts/Eq';

import {User} from '../model/domain/User';
import {userState} from '../model/state/userState';
import PreferencesInputContainer from '../components/input/PreferencesInputContainer';
import UserService from '../service/UserService';
import {debug} from '../util/logger';
import {useNavigate} from 'react-router-dom';
import NextButton from '../components/buttons/NextButton';
import BSHourPicker from '../components/input/HourPicker';
import {Hour} from '../model/domain/Hour';
import ToggleSwitch from '../components/input/ToggleSwitch';
import {WeekDayEnum, weekDayToString} from '../model/domain/enum/WeekDayEnum';
import {compareSeq} from '../util/arrayUtil';
import {PopupConfirmation} from '../components/PopupConfirmation';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';
import {BSTask} from '../model/types';
import AuthService from '../service/AuthService';
import {agreementState} from '../model/state/agreementState';
import {LoadingFallback} from '../components/progress/LoadingFallback';

const useStyles = makeStyles((theme: BSTheme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));
interface SettingsProps {}

const SettingsAuthUser: React.FC<SettingsProps> = ({}) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useRecoilState(userState);
  const setAgreement = useSetRecoilState(agreementState);

  const signInWithTokenTask = useCallback<() => BSTask<User>>(
    () => pipe(AuthService.signInWithToken()),
    [],
  );

  const onSignInError = useCallback(() => {
    navigate('/');
    setUser(O.none);
    // eslint-disable-next-line
  }, [navigate]);

  const onSignInOk = useCallback(
    (u: User): void => {
      setUser(O.some(u));
      if (u.partner.showTermOfUse) {
        setAgreement(u.partner.termOfUseUrl);
      }
    },
    // eslint-disable-next-line
    [setUser, navigate],
  );

  const signInOnStartup = useCallback(async () => {
    return pipe(
      signInWithTokenTask(),
      T.map(E.fold(onSignInError, onSignInOk)),
      (invokeTask: T.Task<void>) => invokeTask(),
    );
  }, [signInWithTokenTask, onSignInError, onSignInOk]);

  useEffect(() => {
    (async function foo() {
      setLoading(true);
      await signInOnStartup();
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return isLoading ? <LoadingFallback /> : <Settings />;
};

const Settings: React.FC<SettingsProps> = ({}) => {
  const themeStyles = useStyles();
  let navigate = useNavigate();

  const [inProgress, setInProgress] = useState(false);

  const [user, setUser] = useRecoilState(userState);
  const [name, setName] = useState(
    pipe(
      user,
      O.chain((u: User) => u.username),
      O.getOrElse<string>(() => ''),
    ),
  );
  const [remindDt, setRemindDt] = useState<O.Option<Hour>>(
    pipe(
      user,
      O.chain((u: User) => u.remindDt),
    ),
  );
  const [remindDays, setRemindDays] = useState<WeekDayEnum[]>(
    pipe(
      user,
      O.map((u: User) => u.remindDays),
      O.getOrElse<WeekDayEnum[]>(() => []),
    ),
  );

  const [notifyEmail, setNotifyEmail] = useState<boolean>(
    pipe(
      user,
      O.map((u: User) => u.notifyEmail),
      O.getOrElse<boolean>(() => false),
    ),
  );

  const [notifyApp, setNotifyApp] = useState<boolean>(
    pipe(
      user,
      O.map((u: User) => u.notifyApp),
      O.getOrElse<boolean>(() => false),
    ),
  );

  const isOn = useCallback(
    (day: WeekDayEnum) => remindDays.indexOf(day) !== -1,
    [remindDays],
  );

  const reminderDaysDisabled =
    !O.toNullable(user)?.partner.allowUsersChangeRemindDays;
  const reminderTimeDisabled =
    !O.toNullable(user)?.partner.allowUsersChangeRemindTime;

  const reminderDays = useMemo(
    () => [
      {weekDay: WeekDayEnum.Sunday, on: isOn(WeekDayEnum.Sunday)},
      {weekDay: WeekDayEnum.Monday, on: isOn(WeekDayEnum.Monday)},
      {weekDay: WeekDayEnum.Tuesday, on: isOn(WeekDayEnum.Tuesday)},
      {weekDay: WeekDayEnum.Wednesday, on: isOn(WeekDayEnum.Wednesday)},
      {weekDay: WeekDayEnum.Thursday, on: isOn(WeekDayEnum.Thursday)},
      {weekDay: WeekDayEnum.Friday, on: isOn(WeekDayEnum.Friday)},
      {weekDay: WeekDayEnum.Saturday, on: isOn(WeekDayEnum.Saturday)},
    ],
    [isOn],
  );

  const toggleRemindDay = (day: WeekDayEnum, on: boolean): void => {
    if (on) {
      setRemindDays((dd) => A.union(eqNumber)(dd, [day]) as WeekDayEnum[]);
    } else {
      setRemindDays((dd) => A.filter((a: WeekDayEnum) => a !== day)(dd));
    }
  };

  useEffect(() => {
    document.title = 'Preferences – Blank Slate';
  }, []);

  const hasChanges = useMemo(
    () =>
      pipe(
        user,
        O.fold(
          () => false,
          (u: User) => {
            debug('u.username=', u.username, ', O.some(name)=', O.some(name));
            debug('u.remindDt=', u.remindDt, ', remindDt=', remindDt);
            debug('u.remindDays=', u.remindDays, ', remindDays=', remindDays);
            const nameEq = pipe(
              u.username,
              O.map((n: string) => n === name),
              O.getOrElse(() => name.length <= 0),
            );
            const remindDtEq = u.remindDt === remindDt;
            const remindDaysEq = compareSeq(eqNumber)(
              u.remindDays,
              remindDays,
              true,
            );
            const eqNotifyEmail = notifyEmail === u.notifyEmail;
            const eqNotifyApp = notifyApp === u.notifyApp;
            debug(
              'nameEq=',
              nameEq,
              ', remindDtEq=',
              remindDtEq,
              ', remindDaysEq=',
              remindDaysEq,
              ', eqNotifyEmail=',
              eqNotifyEmail,
              ', eqNotifyApp=',
              eqNotifyApp,
            );
            const eq =
              nameEq &&
              remindDtEq &&
              remindDaysEq &&
              eqNotifyEmail &&
              eqNotifyApp;
            debug('eq=', eq);
            return !eq;
          },
        ),
      ),
    [user, name, remindDt, remindDays, notifyEmail, notifyApp],
  );

  const onError = useCallback(
    (e: BSError): void => {
      debug('PreferencesScreen error', e);
      setInProgress(false);
    },
    [setInProgress],
  );

  const navigateToMainMenu = useCallback(() => {
    navigate('/welcomeBack');
  }, [navigate]);

  const onOk = useCallback((): void => {
    setInProgress(false);
    navigateToMainMenu();
  }, [navigateToMainMenu, setInProgress]);

  const collectSettings = (): O.Option<User> =>
    pipe(
      user,
      O.map(
        (u: User) =>
          ({
            ...u,
            username: O.fromNullable(name),
            remindDt: remindDt,
            // schedulerType: schedulerType,
            remindDays: remindDays,
            notifyEmail: notifyEmail,
            notifyApp: notifyApp,
            // theme: O.some(theme),
          } as User),
      ),
    );

  const setToast = useError(true);

  const updateUser = (u: User): Promise<void> => {
    return pipe(
      UserService.updateSetting(u),
      TE.chainFirst(() =>
        TE.fromIO(() => {
          debug('Set User', u);
          setUser(O.some(u));
        }),
      ),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
      (invokeTask: T.Task<void>) => invokeTask(),
    );
  };

  const onSave = async (): Promise<void> => {
    debug('Saving preferences...');
    setInProgress(true);
    await pipe(
      collectSettings(),
      O.fold(
        () => Promise.resolve(),
        (u: User) => {
          return updateUser(u);
        },
      ),
    );
  };

  const [showAccountRemovalDialog, setShowAccountRemovalDialog] =
    useState(false);

  const onRemoveAccountError = () => {};
  const onRemoveAccountOk = () => {
    setUser(O.none);
    navigate('/signin');
  };

  return (
    <div className={styles.root}>
      {showAccountRemovalDialog && (
        <PopupConfirmation
          title="Do you really want to completely remove your account? This action cannot be undone"
          onCancel={() => {
            setShowAccountRemovalDialog(false);
          }}
          onConfirm={pipe(
            UserService.removeCurrentUser(),
            TE.mapLeft(setToast),
            T.map(E.fold(onRemoveAccountError, onRemoveAccountOk)),
          )}
        />
      )}
      <div className={clsx(styles.title, themeStyles.title)}>Preferences</div>
      <div className={styles.row}>
        <div style={{width: '450px', paddingRight: '40px'}}>
          <PreferencesInputContainer title={'Username'}>
            <TextInput
              placeholder={'Type your first and last name'}
              value={name}
              onChange={setName}
            />
          </PreferencesInputContainer>
        </div>
        <div style={{width: '280px', paddingRight: '40px'}}>
          <PreferencesInputContainer title={'Customize your reminders time'}>
            <BSHourPicker
              disabled={reminderTimeDisabled}
              value={remindDt}
              onChange={setRemindDt}
            />
          </PreferencesInputContainer>
        </div>
      </div>
      <div className={styles.row}>
        <PreferencesInputContainer
          title={'Days'}
          titleStyle={{paddingLeft: 0, fontSize: 16, marginBottom: '12px'}}>
          <div className={styles.row2}>
            {reminderDays.map(({weekDay, on}) => (
              <div style={{marginRight: '40px'}} key={weekDay}>
                <ToggleSwitch
                  disabled={reminderDaysDisabled}
                  name={weekDayToString(weekDay)}
                  checked={on}
                  onChange={(on: boolean) => toggleRemindDay(weekDay, on)}
                />
              </div>
            ))}
          </div>
        </PreferencesInputContainer>
      </div>
      <div className={styles.row}>
        <PreferencesInputContainer
          title={'What type of reminder would you prefer?'}
          titleStyle={{paddingLeft: 0, fontSize: 18, marginBottom: '12px'}}>
          <div className={styles.row2}>
            <ToggleSwitch
              name={'Send a reminder to your email address'}
              checked={notifyEmail}
              onChange={setNotifyEmail}
              style={{marginRight: '30px'}}
            />
            <ToggleSwitch
              name={'Push notifications to your mobile devices'}
              checked={notifyApp}
              onChange={setNotifyApp}
            />
          </div>
        </PreferencesInputContainer>
      </div>
      {/* <div className={styles.row}>
        <PreferencesInputContainer
          title={'Want to remove your account?'}
          titleStyle={{paddingLeft: 0, fontSize: 18, marginBottom: '12px'}}>
          <div className={styles.row2}>
            <button
              className={styles.removeAccountButton}
              onClick={() => {
                debug('remove account button clicked');
                setShowAccountRemovalDialog(true);
              }}>
              Remove Account
            </button>
          </div>
        </PreferencesInputContainer>
      </div> */}
      <div className={styles.buttonsContainer}>
        <NextButton
          text={'Save'}
          onClick={onSave}
          disabled={!hasChanges || inProgress}
        />
      </div>
    </div>
  );
};

export default SettingsAuthUser;
