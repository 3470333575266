import React, {useMemo} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import QuestionBanner from './QuestionBanner';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    maxHeight: '100px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    fontSize: '55px',
    textTransform: 'uppercase',
    marginBottom: '34px',
    textAlign: 'center',
  },
  inner: {
    display: 'flex',
    flex: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  questionLabel: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.extended.bannerTextColor,
    backgroundColor: theme.palette.extended.bannerBackgroundRedColor,
    border: 'solid 1px ' + theme.palette.extended.bannerBackgroundRedColor,
    borderRadius: '10px',
    padding: '0 10px',
    margin: '20px 4px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    fontSize: '22px',
  },
}));

export interface CardTitleProps {
  text: string;
  labelText: O.Option<string>;
}

const CardTitle: React.FC<CardTitleProps> = ({text, labelText}) => {
  const classes = useStyles();
  const label = pipe(
    labelText,
    O.map((s: string) => <div className={classes.questionLabel}>{s}</div>),
    O.getOrElse(() => <></>),
  );
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        {label}
        {text}
      </div>
    </div>
  );
};

export default CardTitle;
