import {Button, Icon, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';
import React from 'react';
import iconRightSvg from '../../assets/images/icon-right.svg';
import {ButtonBadge} from './ButtonBadge';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    width: '220px',
    height: '48px',
    borderRadius: '12px',
    backgroundColor: '#1C1C1E',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#1C1C1E',
    },
    '&:active': {
      backgroundColor: '#1C1C1E',
    },
    fontFamily: 'RobotoFlex',
    fontWeight: 700,
    fontSize: '20px',
    color: '#FFF',
    marginBottom: 20,
  },
  buttonInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  iconContainer: {
    right: 0,
    position: 'absolute',
    paddingRight: '24px',
  },
  icon: {
    display: 'contents',
  },
}));

export interface ShowAnswerButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const ShowAnswerButton: React.FC<ShowAnswerButtonProps> = ({
  text,
  onClick,
  disabled = false,
}) => {
  const classes = useStyles();
  const iconRight = (
    <Icon className={classes.icon}>
      <img src={iconRightSvg} />
    </Icon>
  );
  return (
    <Button disabled={disabled} className={classes.root} onClick={onClick}>
      <div className={classes.buttonInnerContainer}>{text}</div>
      <div className={classes.iconContainer}>{iconRight}</div>
      <ButtonBadge text="Space" />
    </Button>
  );
};

export default ShowAnswerButton;
