import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/SignUp.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import {useNavigate} from 'react-router-dom';
import AuthButton from '../components/buttons/AuthButton';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import {User} from '../model/domain/User';
import AuthService from '../service/AuthService';
import {useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import {BackButton} from './PasswordReset';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';
import {
  validateEmail,
  validateEmailConfirmation,
  validatePassword,
  validatePasswordConfirmation,
  validateText,
} from '../vallidation/validation';
import {FieldWithValidation} from '../components/input/FieldWithValidation';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    paddingTop: '87px',
    backgroundColor: theme.palette.extended.authBackgroundColor,
  },
}));

export type LinkingData = {
  app: O.Option<string>;
  predefinedEmail: O.Option<string>;
  predefinedInviteCode: O.Option<string>;
};

export const passwordLengthRequirement = 12;

interface SignUpProps {}

const initErrorState = {
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  username: '',
};

const SignUp: React.FC<SignUpProps> = ({}) => {
  const themeStyles = useStyles();
  let navigate = useNavigate();

  const setUser = useSetRecoilState(userState);

  const [app, setApp] = useState<O.Option<string>>(O.none);
  const [predefinedEmail, setPredefinedEmail] = useState<O.Option<string>>(
    O.none,
  );
  const [predefinedInviteCode, setPredefinedInviteCode] = useState<
    O.Option<string>
  >(O.none);

  const [inviteCode, setInviteCode] = useState<string>('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initErrorState);

  const validationMap = {
    email: () =>
      setErrorMessage({...errorMessage, email: validateEmail(email)}),
    confirmEmail: () =>
      setErrorMessage({
        ...errorMessage,
        confirmEmail: validateEmailConfirmation(confirmEmail, email),
      }),
    password: () =>
      setErrorMessage({...errorMessage, password: validatePassword(password)}),
    confirmPassword: () =>
      setErrorMessage({
        ...errorMessage,
        confirmPassword: validatePasswordConfirmation(
          confirmPassword,
          password,
        ),
      }),
    username: () =>
      setErrorMessage({...errorMessage, username: validateText(username)}),
  };

  const fieldsConfig = {
    username: {
      placeholder: 'First and Last name',
      secure: false,
      isDisabled: false,
      value: username,
      onChange: setUsername,
      onBlur: validationMap.username,
    },
    email: {
      placeholder: 'Enter email',
      secure: false,
      isDisabled: O.isSome(predefinedEmail),
      value: email,
      onChange: setEmail,
      onBlur: validationMap.email,
    },
    confirmEmail: {
      placeholder: 'Retype email',
      secure: false,
      isDisabled: O.isSome(predefinedEmail),
      value: confirmEmail,
      onChange: setConfirmEmail,
      onBlur: validationMap.confirmEmail,
    },
    password: {
      placeholder: 'Enter New password',
      secure: true,
      isDisabled: false,
      value: password,
      onChange: setPassword,
      onBlur: validationMap.password,
    },
    confirmPassword: {
      placeholder: 'Retype New Password',
      secure: true,
      isDisabled: false,
      value: confirmPassword,
      onChange: setConfirmPassword,
      onBlur: validationMap.confirmPassword,
    },
  };

  const onOk = (user: User): void => {
    setInProgress(false);
    setErrorMessage(initErrorState);
    setUser(O.some(user));
    navigate('/tour');
  };

  const onError = (e: BSError): void => {
    setInProgress(false);
  };

  const setToast = useError(true);

  const signUp = (
    email: string,
    password: string,
    username: string,
    inviteCode?: string,
  ) =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage(initErrorState);
      }),
      TE.chain(() =>
        AuthService.signUpForInvited(email, password, username, inviteCode),
      ),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );

  const signUpWithApplication = (
    email: string,
    password: string,
    username: string,
    application: string,
    inviteCode?: string,
  ) =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage(initErrorState);
      }),
      TE.chain(() =>
        AuthService.signUpWithApplication(
          email,
          password,
          username,
          application,
          inviteCode,
        ),
      ),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );

  const parseUrl = (u: string): O.Option<LinkingData> => {
    const url = u.toLowerCase();
    const includedSignup = url.includes('signup?');

    if (includedSignup) {
      const queryString = url.split('signup?')[1];
      const queryParams = new URLSearchParams(
        includedSignup ? decodeURIComponent(queryString) : '',
      );

      const app = O.fromNullable(queryParams.get('app'));
      let predefinedEmail = O.fromNullable(queryParams.get('email'));
      const predefinedInviteCode = O.fromNullable(
        queryParams.get('invite_code'),
      );
      if (O.isNone(predefinedEmail) && O.isNone(predefinedInviteCode))
        predefinedEmail = O.fromNullable(decodeURIComponent(queryString));

      return O.some({app, predefinedEmail, predefinedInviteCode});
    }
    return O.none;
  };

  const onSignUp = async () => {
    const task = pipe(
      app,
      O.fold(
        () => signUp(email, password, username, inviteCode),
        (application: string) =>
          signUpWithApplication(
            email,
            password,
            username,
            application,
            inviteCode,
          ),
      ),
    );

    await executeTask(task);
  };

  useEffect(() => {
    pipe(
      parseUrl(location.href),
      O.chain(({app, predefinedEmail, predefinedInviteCode}: LinkingData) => {
        setApp(app);
        setPredefinedEmail(predefinedEmail);
        setPredefinedInviteCode(predefinedInviteCode);
        if (O.isSome(predefinedInviteCode)) {
          setInviteCode(predefinedInviteCode.value);
        }
        return pipe(
          predefinedEmail,
          O.map((pe) => {
            setEmail(pe);
            setConfirmEmail(pe);
          }),
        );
      }),
    );
  }, []);
  useEffect(() => {
    document.title = 'Sign Up – Blank Slate';
  }, []);

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.titleContainer}>Sign Up</div>
      {O.isSome(predefinedEmail) === false && (
        <div className={styles.passwordInput}>
          <AuthInput
            key={'inviteCode'}
            placeholder={'Enter invite code'}
            secure={false}
            isDisabled={O.isSome(predefinedInviteCode)}
            value={inviteCode}
            onChange={setInviteCode}
          />
        </div>
      )}
      {Object.entries(fieldsConfig).map(([key, config]) => (
        <div className={styles.passwordInput} key={key}>
          <FieldWithValidation
            errorMessage={errorMessage[key as keyof typeof errorMessage]}
            infoMessage={
              key === 'password'
                ? `${passwordLengthRequirement} characters or longer.\nAt least one number or symbol.\nAt least one uppercase letter.`
                : undefined
            }>
            <AuthInput
              isError={
                typeof errorMessage[key as keyof typeof errorMessage] !==
                  'undefined' &&
                errorMessage[key as keyof typeof errorMessage] !== ''
              }
              key={key}
              placeholder={config.placeholder}
              secure={config.secure}
              isDisabled={config.isDisabled}
              value={config.value}
              onChange={config.onChange}
              onBlur={config.onBlur}
            />
          </FieldWithValidation>
        </div>
      ))}
      <div className={styles.signInButtonContainer}>
        <AuthButton
          text={'Sign up'}
          onClick={onSignUp}
          disabled={
            inProgress ||
            email.length == 0 ||
            email !== confirmEmail ||
            password.length == 0 ||
            password != confirmPassword ||
            username.length === 0 ||
            Object.values(errorMessage).some(Boolean)
          }
        />
      </div>
      <BackButton />
    </div>
  );
};

export default SignUp;
