import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '21px',
    paddingTop: '5px',
  },
}));

export const NoData: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>None Found</p>
    </div>
  );
};
