import React, {useCallback, useMemo} from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CardHtml} from '../../model/domain/CardHtml';
import {AnswerEnum} from '../../model/domain/enum/AnswerEnum';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import CardResultExclamation from './CardResultExclamation';
import {CardDifficultyMode} from '../../model/domain/enum/CardDifficultyMode';
import CardWebView from './CardWebView';
import CardDifficultyChoice from './CardDifficultyChoice';
import CardTitle from './CardTitle';
import CardAnswerOptions from './CardAnswerOptions';
import {TmplField} from '../../model/domain/TmplField';
import CardService from '../../service/CardService';

const useStyles = makeStyles((theme: Theme) => ({
  cardResultContent: {
    marginTop: 20,
  },
}));

export interface CardBackProps {
  isCorrect: O.Option<boolean>;
  isAnswerTimeExceeded: boolean;
  card: CardHtml;
  onAnswer: (answer: AnswerEnum) => void;
  nextQuestionButtonText: string;
  studyMode?: string;
}

const CardBack: React.FC<CardBackProps> = ({
  isCorrect,
  isAnswerTimeExceeded,
  card,
  onAnswer,
  nextQuestionButtonText,
  studyMode,
}) => {
  const classes = useStyles();

  const correctOptions = useMemo(
    () =>
      pipe(
        card.answerTmplField,
        O.chain((b: TmplField) => b.l),
        O.getOrElse<string[]>(() => []),
      ),
    [card.answerTmplField],
  );

  const singleChoice = useMemo(() => CardService.isSingleChoice(card), [card]);
  const isTrueFalse = useMemo(() => CardService.isTrueFalse(card), [card]);

  const ordered = useMemo(() => CardService.isOrdered(card), [card]);

  const AnswerOptions = useCallback(
    () => (
      <CardAnswerOptions
        cardCounter={0}
        disabled={true}
        showCounterForSelectedOptions={false}
        showCounterForUnselectedOptions={true}
        trueFalse={isTrueFalse}
        textMode={false}
        singleChoice={singleChoice}
        ordered={ordered}
        options={correctOptions}
        onSelectionChanged={() => {}}
      />
    ),
    [isTrueFalse, singleChoice, ordered, correctOptions],
  );

  const title = useMemo(
    () =>
      pipe(
        isCorrect,
        O.map((v: boolean) => {
          if (v) {
            return <CardResultExclamation isCorrect={v} />;
          } else {
            return (
              <div className={classes.cardResultContent}>
                <CardResultExclamation isCorrect={v} />
                {correctOptions.length === 0 ? (
                  <CardWebView html={card.b} />
                ) : (
                  <AnswerOptions />
                )}
              </div>
            );
          }
        }),
        O.getOrElse(() => (
          <>
            <CardTitle text={'answer:'} labelText={O.none} />
            <CardWebView html={card.b} />
          </>
        )),
      ),
    [
      classes.cardResultContent,
      card.b,
      isCorrect,
      AnswerOptions,
      correctOptions,
    ],
  );

  const difficultyChoiceMode = useMemo(
    () =>
      pipe(
        isCorrect,
        O.map((isCorrect: boolean) =>
          isCorrect
            ? isAnswerTimeExceeded
              ? CardDifficultyMode.AnswerTimeExceeded
              : CardDifficultyMode.Answer
            : CardDifficultyMode.Wrong,
        ),
        O.getOrElse<CardDifficultyMode>(() =>
          isAnswerTimeExceeded
            ? CardDifficultyMode.AnswerTimeExceeded
            : CardDifficultyMode.EasyHardWrong,
        ),
      ),
    [isCorrect, isAnswerTimeExceeded],
  );

  return (
    <>
      {title}
      <CardDifficultyChoice
        mode={difficultyChoiceMode}
        onAnswer={(a: AnswerEnum) => onAnswer(a)}
        nextQuestionButtonText={nextQuestionButtonText}
        studyMode={studyMode}
      />
    </>
  );
};

export default CardBack;
