import {NextStepTO} from '../to/NextStepTO';
import {NextStep} from '../../model/domain/NextStep';
import StudyStatConverter from './StudyStatConverter';
import CardHtmlConverter from './CardHtmlConverter';
import * as O from 'fp-ts/lib/Option';
import {pipe} from 'fp-ts/lib/function';

const toDomain = (to: NextStepTO): NextStep =>
  ({
    c: pipe(O.fromNullable(to.c), O.map(CardHtmlConverter.toDomain)),
    s: StudyStatConverter.toDomain(to.s),
    progress: to.progress,
    msg: to.msg,
    streak: to.streak,
    mode: to.mode,
  } as NextStep);

export default {
  toDomain,
};
