import {PartnerTO} from '../to/PartnerTO';
import {Partner} from '../../model/domain/Partner';

const toDomain = (to: PartnerTO): Partner =>
  ({
    id: to.id,
    logoId: to.l,
    url: to.u,
    showTermOfUse: to.showTermOfUse,
    termOfUseUrl: to.termOfUseUrl,
    showFeedbackAlways: to.showFeedbackAlways,
    showLeaderboard: to.showLeaderboard,
    enableLibrary: to.enableLibrary,
    enableExternalAnalytics: to.enableExternalAnalytics,
    allowUsersChangeRemindDays: to.allowUsersChangeRemindDays,
    allowUsersChangeRemindTime: to.allowUsersChangeRemindTime
  } as Partner);

const fromDomain = (d: Partner): PartnerTO =>
  ({
    id: d.id,
    l: d.logoId,
  } as PartnerTO);

export default {
  toDomain,
  fromDomain,
};
