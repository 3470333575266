import React from 'react';
import { MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: '12px',
    border: 'solid 1px ' + theme.palette.extended.inputBorderColor,
    position: 'relative',
    backgroundColor: theme.palette.extended.transparent,
    height: '50px',
    padding: '0 16px',
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

export interface DropDownItem {
  value: number;
  text: string;
}

export interface DropDownProps {
  items: DropDownItem[];
  value: number;
  onChange: (value: unknown) => void;
  disabled?: boolean;
}

const DropDown: React.FC<DropDownProps> = ({
  items,
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      value={value}
      variant="standard"
      onChange={(e) => onChange(e.target.value)}>
      {items.map((item: DropDownItem) => (
        <MenuItem key={item.text} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DropDown;
