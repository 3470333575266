import {atom, selector, useRecoilCallback} from 'recoil';
import * as O from 'fp-ts/Option';
import {Option} from 'fp-ts/Option';
import {NextStep, NextStepProgressWithReview} from '../domain/NextStep';
import {pipe} from 'fp-ts/lib/function';
import {CardHtml} from '../domain/CardHtml';
import {ReviewAdditionalCards, StudyStat} from '../domain/StudyStat';

export const nextStepState = atom<Option<NextStep>>({
  key: 'nextStepState',
  default: O.none,
});

export const nextStepCounterState = atom<number>({
  key: 'nextStepCounter',
  default: 0,
});

export const useUpdateStepState = () => useRecoilCallback(({set}) => (newData: Option<NextStep>) => {
  set(nextStepState, newData);
  set(nextStepCounterState, (old) => old + 1)
}, []);

export const nextCardState = selector<Option<CardHtml>>({
  key: 'nextCardState',
  get: ({get}) =>
    pipe(
      get(nextStepState),
      O.chain((nextStep: NextStep) => nextStep.c),
    ),
});

export const nextStudyStatState = selector<Option<StudyStat>>({
  key: 'nextStudyStatState',
  get: ({get}) =>
    pipe(
      get(nextStepState),
      O.map((nextStep: NextStep) => nextStep.s),
    ),
});

export const nextStudyProgressState = selector<Option<NextStepProgressWithReview>>({
  key: 'nextStudyProgressState',
  get: ({get}) =>
    pipe(
      get(nextStepState),
      O.map((nextStep: NextStep) => ({ progress: nextStep.progress, mode: nextStep.mode, tomorrow: nextStep.s.tomorrow, s: nextStep.s })),
    ),
});

export const additionalStudyState = selector<Option<ReviewAdditionalCards>>({
  key: 'additionalStudyState',
  get: ({get}) =>
    pipe(
      get(nextStepState),
      O.map((nextStep: NextStep) => {
        return ({ mode: nextStep.mode, msg: nextStep.msg || '' })
      }),
    ),
});
