import React, {useMemo} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  message: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    fontSize: '45px',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: '30px'
  },
  messageCorrect: {
    color: theme.palette.extended.correctColor,
  },
  messageIncorrect: {
    color: theme.palette.extended.incorrectColor,
  },
}));

export interface CorrectnessMessageProps {
  isCorrect: boolean;
}

const CorrectnessMessage: React.FC<CorrectnessMessageProps> = ({isCorrect}) => {
  const classes = useStyles();
  const message = useMemo(
    () =>
      isCorrect ? ['That’s correct!'] : ['Whoops!', 'The correct answer was:'],
    [isCorrect],
  );

  const messageStyle = useMemo(
    () => (isCorrect ? classes.messageCorrect : classes.messageIncorrect),
    [isCorrect, classes],
  );

  return (
    <>
      <div className={clsx(classes.message, messageStyle)}>
        {message.map((m) => (
          <div key={m}>{m}</div>
        ))}
      </div>
    </>
  );
};

export default CorrectnessMessage;
