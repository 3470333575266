import React from 'react';
import SingleChoiceButtonsGroup from './SingleChoiceButtonsGroup';
import {optionToArray} from '../../util/arrayUtil';
import OrderedChoiceButtonsGroup from './OrderedChoiceButtonsGroup';
import MultipleChoiceButtonsGroup from './MultipleChoiceButtonsGroup';
import {TextAnswerGroup} from '../TextAnswerGroup';

export interface CardAnswerOptionsProps {
  cardCounter: number;
  disabled: boolean;
  showCounterForSelectedOptions: boolean;
  showCounterForUnselectedOptions: boolean;
  trueFalse: boolean;
  textMode: boolean;
  surveyMode?: boolean;
  singleChoice: boolean;
  ordered: boolean;
  options: string[];
  showBadge?: boolean;
  onSelectionChanged: (selected: string[]) => void;
}

const CardAnswerOptions: React.FC<CardAnswerOptionsProps> = ({
  cardCounter,
  disabled,
  showCounterForSelectedOptions,
  showCounterForUnselectedOptions,
  trueFalse,
  textMode,
  singleChoice,
  ordered,
  options,
  onSelectionChanged,
  showBadge = false,
  surveyMode = false,
}) => {
  return textMode ? (
    <TextAnswerGroup
      key={cardCounter}
      onSelectionChanged={(a) => onSelectionChanged(optionToArray(a))}
      disabled={disabled}
      surveyMode={surveyMode}
    />
  ) : trueFalse ? (
    <SingleChoiceButtonsGroup
      key={cardCounter}
      options={options.map((option) => option.toUpperCase())}
      onSelectionChanged={(a) => onSelectionChanged(optionToArray(a))}
      disabled={disabled}
      showBadge={showBadge}
      surveyMode={surveyMode}
    />
  ) : singleChoice ? (
    <SingleChoiceButtonsGroup
      key={cardCounter}
      options={options}
      onSelectionChanged={(a) => onSelectionChanged(optionToArray(a))}
      disabled={disabled}
      showBadge={showBadge}
      surveyMode={surveyMode}
    />
  ) : ordered ? (
    <OrderedChoiceButtonsGroup
      key={cardCounter}
      options={options}
      showCounterForSelectedOptions={showCounterForSelectedOptions}
      showCounterForUnselectedOptions={showCounterForUnselectedOptions}
      onSelectionChanged={onSelectionChanged}
      disabled={disabled}
      showBadge={showBadge}
      surveyMode={surveyMode}
    />
  ) : (
    <MultipleChoiceButtonsGroup
      key={cardCounter}
      options={options}
      onSelectionChanged={onSelectionChanged}
      disabled={disabled}
      showBadge={showBadge}
      surveyMode={surveyMode}
    />
  );
};

export default CardAnswerOptions;
