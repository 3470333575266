import {BSTask} from '../model/types';
import {pipe} from 'fp-ts/lib/function';
import RestService from './RestService';
import * as TE from 'fp-ts/TaskEither';
import {BSTaskVoid} from '../util/bs-fp';

const sendFeedback = (message: string): BSTask<void> => {
  const url = '/app/feedback';
  const data = 'msg=' + encodeURIComponent(message);
  return pipe(
    RestService.post(url, data),
    TE.chain(() => BSTaskVoid),
  );
};

export default {
  sendFeedback,
};
