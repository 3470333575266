import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';
import {nullableNumber, nullableString, arrayOfStrings} from './commonTypes';
import {TmplFieldTODecoder} from './TmplFieldTO';

export const CardHtmlTODecoder = io.type({
  cId: io.number,
  dId: io.number,
  f: io.string,
  b: io.string,
  fl: io.array(TmplFieldTODecoder),
  dueDt: arrayOfStrings,
  qFieldType: nullableNumber,
  fFieldType: nullableNumber,
  deckTyp: io.number,
  bFieldType: nullableNumber,
  bComment: nullableString,
  questionType: io.number,
  questionBanner: nullableString,
  questionLabel: nullableString,
  deck: io.string,
});

export type CardHtmlTO = io.TypeOf<typeof CardHtmlTODecoder>;

export const cardHtmlTOResponseDecoder = responseDecoder<CardHtmlTO>(
  CardHtmlTODecoder,
);
