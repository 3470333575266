import React, {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import * as O from 'fp-ts/Option';
import {userState} from '../model/state/userState';
import {pipe} from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import {executeTask} from '../util/bs-fp';
import AuthService from '../service/AuthService';
import {useNavigate} from 'react-router-dom';
import {useError} from '../model/state/errorState';

export const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);
  const onSignOutError = () => {};
  const onSignOutOk = () => {
    setUser(O.none);
    navigate('/signin');
  };

  const setToast = useError(true);

  const sendSignOutRequestTask = () =>
    pipe(
      AuthService.signOut(),
      TE.mapLeft(setToast),
      T.map(E.fold(onSignOutError, onSignOutOk)),
    );

  const signOut = async () => {
    const task = sendSignOutRequestTask();
    await executeTask(task);
  };

  useEffect(() => {
    document.title = 'Logout – Blank Slate';
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};
