import {Theme} from '@emotion/react';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    zIndex: 100,
    paddingTop: '112px',
  },
  root: {
    display: 'flex',
    background: '#FFF',
    borderRadius: '5px',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '385px',
    flexDirection: 'column',
    paddingTop: '40px',
    paddingBottom: '40px',
    paddingLeft: '28px',
    paddingRight: '28px',
    gap: '46px',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 300,
    color: '#000',
  },
  button: {
    padding: '6px',
    background: '#FFF',
    color: '#000',
    fontFamily: 'Roboto',
    fontWeight: 500,
    minWidth: '93px',
    fontSize: '14px',
    borderRadius: '14px',
    border: '1px solid #79747E',
  },
}));

interface InformationPopupProps {
  message: string;
  onClose: () => void;
}

export const InformationPopup: React.FC<InformationPopupProps> = ({
  message,
  onClose = () => {},
}) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <p className={styles.text}>{message}</p>
        <button
          className={styles.button}
          type="button"
          onClick={() => {
            onClose();
          }}>
          Got it!
        </button>
      </div>
    </div>
  );
};
