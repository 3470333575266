import React, {useEffect, useMemo, useState} from 'react';
import styles from '../styles/components/NotificationPopup.module.css';

interface NotificationPopupProps {
  message: JSX.Element | string | null;
  redNumbers?: boolean;
  onEnd?: () => void;
  showCloseCheckmark?: boolean;
}

export const NotificationPopup: React.FC<NotificationPopupProps> = ({
  message,
  redNumbers = false,
  onEnd = () => {},
  showCloseCheckmark = false,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [isPresented, setPresented] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          setPresented(false);
          onEnd();
        }, 1000);
      }, 3500);
    }, 500);
  }, [onEnd]);

  const messageParts: string[] | undefined = useMemo(() => {
    if (typeof message === 'string') return message?.split(/(\d+)/);
  }, [message]);

  return (
    <>
      {message !== null && isPresented ? (
        <div
          style={{opacity: isVisible ? '90%' : '0%'}}
          className={styles.root}>
          <p className={styles.text}>
            {redNumbers && messageParts && messageParts.length >= 3 ? (
              <span>
                {messageParts[0]}
                <span style={{color: '#FF0000', fontWeight: 700}}>
                  {messageParts[1]}
                </span>
                {messageParts[2]}
              </span>
            ) : (
              message
            )}
          </p>
          {showCloseCheckmark && (
            <div className={styles.buttonContainer}>
              <button
                className={styles.button}
                type="button"
                onClick={() => {
                  setPresented(false);
                  onEnd();
                }}>
                Got it!
              </button>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
