import {Link} from 'react-router-dom';
import React, {useEffect} from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Roboto Condensed',
    paddingLeft: '34px',
    paddingTop: '40px',
    paddingRight: '68px',
  },
  title: {
    fontWeight: 700,
    fontSize: 25,
    margin: '0 0 23px 0',
  },
  itemsContainer: {
    paddingLeft: '5px',
  },
  itemContainer: {
    paddingTop: '18px',
    paddingBottom: '19px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
  },
  itemLink: {
    textDecoration: 'none',
    fontSize: '18px',
    color: '#000',
  },
}));

interface SubMenuPageProps {
  title: string;
  menuItems: {name: string; link: string}[];
}

const SubMenuPage: React.FC<SubMenuPageProps> = ({title, menuItems}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.itemsContainer}>
        {menuItems.map((menuItem) => {
          return (
            <div className={classes.itemContainer} key={menuItem.name}>
              <Link className={classes.itemLink} to={menuItem.link}>
                {menuItem.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const PasswordPreferencesPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Settings – Blank Slate';
  }, []);
  return (
    <SubMenuPage
      title="Settings"
      menuItems={[
        {name: 'Preferences', link: '/preferences'},
        {name: 'Password', link: '/updatePws'},
      ]}
    />
  );
};
