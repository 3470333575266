/* eslint-disable no-restricted-syntax */
import React, {useEffect, useMemo, useState} from "react";
import {useAsyncState} from "../model/state/asyncState";
import StudyService from "../service/StudyService";
import {ErrorPage} from "../components/ErrorInterrupter";
import {LoadingFallback} from "../components/progress/LoadingFallback";
import {Head, SortItem} from "./LibraryPage";
import { useStyles } from "./LibraryPage";

enum ResourcesSortOrder {
  nameForward,
  nameReverse,
}

export const ExternalResourcesList: React.FC = () => {
  const classes = useStyles();
  const state = useAsyncState(StudyService.getUserLibraryResources);

  const [sort, setSort] = useState<ResourcesSortOrder>(
    ResourcesSortOrder.nameForward,
  );
  const sortedResources = useMemo(() => {
    if (!state.data) return [];
    const mutable = [...state.data];
    return mutable.sort((a, b) => {
      const resourceAName = a.title.toLowerCase();
      const resourceBName = b.title.toLowerCase();
      switch (sort) {
        case ResourcesSortOrder.nameForward:
          return resourceAName > resourceBName ? 1 : -1;
        case ResourcesSortOrder.nameReverse:
          return resourceAName > resourceBName ? -1 : 1;
      }
    });
  }, [sort, state]);

  useEffect(() => {
    document.title = 'Library – Blank Slate';
  }, []);

  if (state.error) return <ErrorPage error={state.error} onReset={() => {}} />;

  return (
    <>
      {state.inProgress ? (
        <LoadingFallback />
      ) : (
        <div className={classes.root}>
          <Head
            title="Extra Resources"
            text="Click on any item to review content"
          />
          {sortedResources.length === 0 ? null : (
            <div className={classes.main}>
              <div className={classes.sort}>
                <SortItem
                  disabled={state.inProgress}
                  onClick={() => {
                    if (sort === ResourcesSortOrder.nameForward) {
                      setSort(ResourcesSortOrder.nameReverse);
                    } else {
                      setSort(ResourcesSortOrder.nameForward);
                    }
                  }}
                  name="Extra Resources"
                />
              </div>
              <div className={classes.externalResourcesItems}>
                {sortedResources.map((resource) => {
                  return (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      key={resource.title}
                      className={classes.resourcesItemLink}
                      href={resource.url}>
                      <div className={classes.itemContainer}>
                        <div className={classes.itemText}>
                          <p className={classes.paragraph}>{resource.title}</p>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
