import {UserTO} from '../to/UserTO';
import {AuthUser} from '../../model/domain/AuthUser';
import UserConverter from './UserConverter';

const toDomain = (userTO: UserTO) => {
  return {
    user: UserConverter.toDomain(userTO),
  } as AuthUser;
};

export default {
  toDomain,
};
