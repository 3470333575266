import {Theme} from '@emotion/react';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    zIndex: 100,
    paddingTop: '200px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    display: 'flex',
    background: '#FFF',
    borderRadius: '5px',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '385px',
    flexDirection: 'column',
    paddingTop: '40px',
    paddingBottom: '40px',
    paddingLeft: '28px',
    paddingRight: '28px',
    gap: '46px',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 300,
    color: '#000',
  },
  button: {
    padding: '8px 24px',
    background: '#FFF',
    color: '#000',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '14px',
    border: '1px solid #79747E',
    cursor: 'pointer',
  },
}));

interface InformationPopupProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const InformationTemplatePopup: React.FC<InformationPopupProps> = ({
  children,
  onClose = () => {},
}) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        {children}
        <button
          className={styles.button}
          type="button"
          onClick={onClose}>
          Got it
        </button>
      </div>
    </div>
  );
};
