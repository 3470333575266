import React, {useCallback, useMemo} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';
import {CardHtml} from '../../model/domain/CardHtml';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import CardService from '../../service/CardService';
import QuestionBanner from '../card/QuestionBanner';
import CardTitle from '../card/CardTitle';
import CardWebView from '../card/CardWebView';
import {TmplField} from '../../model/domain/TmplField';
import CardActionBox from '../card/CardActionBox';
import ShowAnswerButton from '../buttons/ShowAnswerButton';
import ButtonsStandardLayout from '../card/ButtonsStandardLayout';
import {CardAnswerButtonProps} from '../buttons/CardAnswerButton';
import LineProgressBar from "../progress/LineProgressBar";

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {},
  animationContainer: {
    // background: 'rgb(213,213,213)',
    background:
      'linear-gradient(90deg, rgba(213, 213, 213, 0.5) 0%, rgba(249, 249, 249, 0.50) 50%, rgba(213, 213, 213, 0.5) 100%)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 50px',
    paddingTop: '50px',
    height: '80vh',
    backgroundColor: 'white',
    borderRadius: '12px',
    overflowY: 'auto',
  },
  cardFrontContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    itemsAlign: 'center',
  },
  buttonBlock: {
    width: '100%',
  },
  buttonBlockItem: {
    width: '15rem',
    textAlign: 'center',
  },
  improveLink: {
    visibility: 'hidden',
    fontSize: '20px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#000',
    paddingTop: '1.9rem',
  },
  feedback: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
  },
  txtFeedback: {
    display: 'flex',
    flex: 1,
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontSize: '32px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  progressBar: {
    width: '100%',
    marginTop: '38px',
    marginBottom: '28px',
    height: '4px',
  },
}));

interface CardReviewProps {
  card: CardHtml;
  deckName?: string;
  onNext: () => void;
  isLast: boolean;
  cardsQuantity: number;
  currentCard: number;
}

export const CardReview: React.FC<CardReviewProps> = ({
  card,
  deckName,
  onNext,
  isLast,
  cardsQuantity,
  currentCard,
}) => {
  const classes = useStyles();

  const feedbackText = useMemo(() => {
    return O.toNullable(card.bComment) || '';
  }, [card]);

  const isTrueFalse = CardService.isTrueFalse(card);
  const correctOptions = useMemo(
    () =>
      pipe(
        card.answerTmplField,
        O.chain((b: TmplField) => b.l),
        O.getOrElse<string[]>(() => []),
      ),
    [card.answerTmplField],
  );

  const incorrectOptions = useMemo(
    () =>
      pipe(
        card.answerTmplField,
        O.chain((b: TmplField) => b.el),
        O.getOrElse<string[]>(() => []),
      ),
    [card.answerTmplField],
  );
  const allOptions = useMemo(() => {
    return correctOptions.concat(incorrectOptions);
  }, [correctOptions, incorrectOptions]);
  const disabled = true;
  const banner = O.toNullable(card.questionBanner);
  const buttonText = isLast ? 'Back to Decks' : 'Next';
  const buttonBuilder = useCallback(
    (selected: boolean) => (text: string, onClick: () => void) =>
      ({text, selected, onClick, disabled} as CardAnswerButtonProps),
    [disabled],
  );

  const selectedButtonBuilder = useMemo(
    () => buttonBuilder(true),
    [buttonBuilder],
  );
  const unselectedButtonBuilder = useMemo(
    () => buttonBuilder(false),
    [buttonBuilder],
  );

  const answers = (isTrueFalse ? ['TRUE', 'FALSE'] : allOptions).map(
    (currentAnswer) => {
      const answersTemplate = O.toNullable(card.answerTmplField);
      const correctAnswersArray =
        answersTemplate === null
          ? ([] as string[])
          : O.toNullable(answersTemplate.l) || ([] as string[]);
      const correctAnswersArrayCaseInsensitive = isTrueFalse
        ? correctAnswersArray.map((item) => item.toUpperCase())
        : [...correctAnswersArray];
      if (correctAnswersArrayCaseInsensitive.includes(currentAnswer)) {
        return selectedButtonBuilder(currentAnswer, () => {});
      } else {
        return unselectedButtonBuilder(currentAnswer, () => {});
      }
    },
  );

  return (
    <>
      <div className={classes.content}>
        {deckName && <QuestionBanner text={deckName}/>}
        {/* <CardTitle text={'question:'} labelText={card.questionLabel} /> */}
        <div className={classes.cardFrontContent}>
          <CardWebView html={card.f}/>
          <ButtonsStandardLayout readOnly showBadge={false} buttons={answers}/>
        </div>
        {feedbackText.length > 0 && (
          <div className={classes.feedback}>
            <CardTitle text={''} labelText={O.none}/>
            <div className={classes.txtFeedback}>{feedbackText}</div>
          </div>
        )}
        <div className={classes.buttonBlock}>
          <div className={classes.buttonBlockItem}></div>
          <div>
            <CardActionBox text="">
              <ShowAnswerButton text={buttonText} onClick={() => onNext()}/>
            </CardActionBox>
          </div>
          <a
            target="_blank"
            className={`${classes.buttonBlockItem} ${classes.improveLink}`}
            href="https://blankslatetech.typeform.com/feedback">
            Help Improve / Blank Slate
          </a>
        </div>
      </div>
      <div className={classes.progressBar}>
        <LineProgressBar steps={cardsQuantity} current={currentCard + 1}/>
      </div>
    </>
  );
};
