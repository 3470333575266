import React, {useCallback, useEffect, useState} from 'react';
import styles from '../styles/pages/SignIn.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import AuthButton from '../components/buttons/AuthButton';
import {debug} from '../util/logger';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import {User} from '../model/domain/User';
import AuthService from '../service/AuthService';
import {useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {useNavigate} from 'react-router-dom';
import BSLogo from '../components/BSLogo';
import {agreementState} from '../model/state/agreementState';
import {BSTask} from '../model/types';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    paddingTop: '50px',
    backgroundColor: theme.palette.extended.authBackgroundColor,
  },
  errorMessage: {
    color: theme.palette.primary.dark,
  },
}));

interface SignInProps {}

const SignIn: React.FC<SignInProps> = ({}) => {
  const themeStyles = useStyles();
  let navigate = useNavigate();

  const setUser = useSetRecoilState(userState);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const setAgreement = useSetRecoilState(agreementState);

  const beforeLogin = useCallback(() => {
    setInProgress(true);
    setErrorMessage(undefined);
  }, []);

  const afterLogin = useCallback(() => setInProgress(false), []);

  const setToast = useError(true);

  const signInTask = (email: string, password: string): BSTask<User> =>
    pipe(
      TE.of(beforeLogin()),
      () => AuthService.signInWithCredentials(email, password),
      TE.mapLeft(setToast),
      T.chainFirst(() => TE.of(afterLogin())),
    );

  const onSignInError = useCallback(
    (e: BSError): void => {
      setUser(O.none);
    },
    [setUser],
  );

  const onSignInOk = useCallback(
    (u: User): void => {
      setErrorMessage(undefined);
      setUser(O.some(u));
      if (u.partner.showTermOfUse) {
        setAgreement(u.partner.termOfUseUrl);
      }
      navigate('/welcomeBack');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUser, navigate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSignIn = async () => {
    await pipe(
      signInTask(email, password),
      T.map(E.fold(onSignInError, onSignInOk)),
      (invokeTask: T.Task<void>) => invokeTask(),
    );
    // window.document.cookie = 't="S_47C1HP3R8wY="; Max-Age=2592000; Expires=Tue, 02 Aug 2022 15:05:54 GMT; Path=/; SameSite=None; Domain=127.0.0.1'
  };

  useEffect(() => {
    const f = onSignIn;
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        debug('Enter key was pressed. Run your function.');
        event.preventDefault();
        f();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onSignIn]);

  useEffect(() => {
    document.title = 'Sign In – Blank Slate';
  }, []);

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.logoContainer}>
        <BSLogo palette={'secondary'} style={{fontSize: '72px'}} />
      </div>
      <div className={styles.authInputContainer}>
        <AuthInput
          key={'email'}
          placeholder={'Enter your email'}
          secure={false}
          value={email}
          onChange={setEmail}
        />
        <div className={styles.passwordInput}>
          <AuthInput
            key={'password'}
            placeholder={'Enter your password'}
            secure={true}
            value={password}
            onChange={setPassword}
          />
        </div>
        <div className={styles.authLinksContainer}>
          <RouterLink to={'/resetPws'}>
            <Link
              className={styles.authLink}
              component="button"
              color={'secondary'}
              tabIndex={-1}>
              Forgot your password?
            </Link>
          </RouterLink>
        </div>
      </div>
      <div className={styles.authInputContainer}>
        <div className={styles.signInButtonContainer}>
          <AuthButton
            text={'Sign In'}
            onClick={onSignIn}
            signInButton={true}
            disabled={inProgress || email.length == 0 || password.length == 0}
          />
          <div className={styles.signUpContainer}>
            {/* <RouterLink to={'/signUp'}>
              <Link
                className={styles.signUpLink}
                component="button"
                color={'secondary'}
                tabIndex={-1}>
                Create an account
              </Link>
            </RouterLink> */}
          </div>
        </div>
        <div className={styles.messageContainer}>
          <span className={clsx(styles.errorMessage, themeStyles.errorMessage)}>
            {errorMessage}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
