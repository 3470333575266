import {BSTask} from '../model/types';
import * as TE from 'fp-ts/TaskEither';
import AppConfig, {isDev} from '../AppConfig';

export const debug = (message?: any, ...params: any[]): void => {
  if (isDev) {
    console.log(message, ...params);
  }
};

export const debugIO = (message?: any, ...params: any[]): BSTask<void> =>
  TE.fromIO(() => debug(message, ...params));

export const debugHtml = (html: string): void => {
  if (AppConfig.debugHtml) {
    debug(html);
  }
};
