interface HighlightTextProps {
  text: string;
  highlight?: string;
}

export const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  highlight,
}) => {
  if (typeof highlight === 'undefined' || !highlight) return <p>{text}</p>;
  const highlightLowerCased = highlight.toLowerCase();
  try {
    const re = new RegExp(`(${highlightLowerCased})`, 'gi');
    const splitedText = text.split(re);

    return (
      <p>
        {splitedText.map((part, index) =>
          part.match(re) ? (
            <span key={index.toString()} style={{color: '#005FDB'}}>
              {part}
            </span>
          ) : (
            <span key={index.toString()}>{part}</span>
          ),
        )}
      </p>
    );
  } catch {
    return <p>{text}</p>;
  }
};
