import {TmplFieldTO} from '../to/TmplFieldTO';
import {TmplField} from '../../model/domain/TmplField';
import {fromNullable} from 'fp-ts/lib/Option';
import {FieldTypeEnum} from '../../model/domain/enum/FieldTypeEnum';

const toDomain = (to: TmplFieldTO): TmplField =>
  ({
    n: to.n,
    typ: to.typ as FieldTypeEnum,
    v: to.v,
    l: fromNullable(to.l),
    //extra values/words
    el: fromNullable(to.el),
    w: fromNullable(to.w),
    h: fromNullable(to.h),
    title: fromNullable(to.title),
    comment: fromNullable(to.comment),
  } as TmplField);

export default {
  toDomain,
};
