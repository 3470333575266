import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardTitle from './CardTitle';
import * as O from 'fp-ts/Option';
import NextQuestionButton from '../buttons/NextQuestionButton';
import {useShortcuts} from '../../hooks/useShortcuts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
  },
  txtFeedback: {
    display: 'flex',
    flex: 1,
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontSize: '32px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  btnNext: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export interface CardFeedbackProps {
  text: string;
  onNext: () => void;
}

const CardFeedback: React.FC<CardFeedbackProps> = ({
  text,
  onNext,
}: CardFeedbackProps) => {
  const classes = useStyles();
  useShortcuts([' '], onNext);
  return (
    <div className={classes.root}>
      <CardTitle text={'feedback:'} labelText={O.none} />
      <div className={classes.txtFeedback}>{text}</div>
      <div className={classes.btnNext}>
        <NextQuestionButton onClick={onNext} text={'Next'} />
      </div>
    </div>
  );
};

export default CardFeedback;
