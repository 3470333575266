import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  div: {
    width: '100%',
    height: '100%',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinningImg: {
    animation: '$spin 3s linear infinite',
    margin: '0 auto',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export const LoadingFallback: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <svg
        className={classes.spinningImg}
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M83.2853 43.9996C83.2853 49.1587 82.2691 54.2672 80.2948 59.0336C78.3206 63.7999 75.4268 68.1308 71.7788 71.7788C68.1308 75.4268 63.7999 78.3206 59.0336 80.2948C54.2672 82.2691 49.1587 83.2853 43.9996 83.2853C38.8405 83.2853 33.7319 82.2691 28.9656 80.2948C24.1992 78.3206 19.8684 75.4268 16.2204 71.7788C12.5724 68.1308 9.6786 63.7999 7.70431 59.0336C5.73002 54.2672 4.71387 49.1587 4.71387 43.9996C4.71387 38.8405 5.73002 33.7319 7.70432 28.9656C9.67861 24.1992 12.5724 19.8684 16.2204 16.2204C19.8684 12.5724 24.1992 9.6786 28.9656 7.70431C33.732 5.73002 38.8405 4.71387 43.9996 4.71387C49.1587 4.71387 54.2672 5.73002 59.0336 7.70432C63.7999 9.67861 68.1308 12.5724 71.7788 16.2204C75.4268 19.8684 78.3206 24.1992 80.2948 28.9656C82.2691 33.732 83.2853 38.8405 83.2853 43.9996L83.2853 43.9996Z"
          stroke="url(#paint0_angular_1547_4867)"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.5 20"
        />
        <defs>
          <radialGradient
            id="paint0_angular_1547_4867"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(43.9996 43.9996) rotate(169.077) scale(39.3207)">
            <stop stopColor="#C8CBCB" stopOpacity="0" />
            <stop offset="1" stopColor="#585959" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};
