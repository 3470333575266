import React, {useCallback, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import {useNavigate} from 'react-router-dom';

import {Performance} from '../components/Performance';
import {useStatsState} from '../model/state/tklStatsState';
import {ErrorPage} from '../components/ErrorInterrupter';
import {useRecoilValue} from "recoil";
import {userState} from "../model/state/userState";
import * as O from 'fp-ts/Option';
import {InformationPopup} from "../components/InformationPopup";
import {InformationTemplatePopup} from "../components/InformationTemplatePopup";

const useStyles = makeStyles((theme: BSTheme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1',
  },
  containerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    padding: '30px 151px',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '636px',
    marginBottom: '40px',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '25px',
    color: theme.palette.primary.main,
    marginBottom: '63px',
  },
  TKLPopupTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '18px',
    marginBottom: '30px',
    color: theme.palette.primary.main,
  },
  TKLPopupText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
  },
  TKLPopupBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PerformancePage: React.FC = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [openTKLInfoPopup, setOpenTKLInfoPopup] = React.useState(false);

  const leaderBoardEnabled = O.toNullable(user)?.partner.showLeaderboard;

  const navigateToMainMenu = useCallback(() => {
    navigate('/welcomeBack');
  }, [navigate]);

  const navigateToTeamPerformance = useCallback(() => {
    navigate('/team-performance');
  }, [navigate]);
  const {data, inProgress, error} = useStatsState();

  useEffect(() => {
    document.title = 'Performance – Blank Slate';
  }, []);

  return (
    <>
      <div className={classes.container}>
        {typeof error !== 'undefined' ? (
          <ErrorPage error={error} onReset={() => {}} />
        ) : (
          <div className={classes.containerBlock}>
            <h1 className={classes.header}>Your daily progress</h1>
            <Performance
              stat={data}
              inProgress={inProgress}
              leaderBoardEnabled={leaderBoardEnabled}
              openTKLPopup={() => setOpenTKLInfoPopup(true)}
            />
          </div>
        )}
      </div>
      {openTKLInfoPopup &&
        <InformationTemplatePopup onClose={() => setOpenTKLInfoPopup(false)}>
          <div className={classes.TKLPopupBlock}>
            <div className={classes.TKLPopupTitle}>TKL</div>
            <div className={classes.TKLPopupText}>
              TKL stands for True Knowledge Level™. This is Blank Slate’s unique analytic that estimates how well
              you know all of your material at this moment. In other words, if you were to take a pop quiz on all
              of the material you've reviewed right now, your TKL is the score you would be predicted to achieve.
            </div>
          </div>
      </InformationTemplatePopup>
      }
    </>
  );
};

export default PerformancePage;
