import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';

export const studyStatTODecoder = io.type({
  n: io.number,
  l: io.number,
  r: io.number,
  done: io.number,
  tomorrow: io.number,
  ignore: io.number,
  hasAssignedCards: io.boolean,
});

export type StudyStatTO = io.TypeOf<typeof studyStatTODecoder>;

export const studyStatTOResponseDecoder = responseDecoder<StudyStatTO>(
  studyStatTODecoder,
);
