import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';
import {nullableNumber, nullableString} from './commonTypes';
import {studyStatTODecoder} from './StudyStatTO';
import {CardHtmlTODecoder} from './CardHtmlTO';

const ProgressTOItemDecoder = io.type({
  total: io.number,
  done: io.number,
});

const StepProgressTODecoder = io.type({
  Main: ProgressTOItemDecoder,
  Review: ProgressTOItemDecoder,
  Survey: ProgressTOItemDecoder,
});

export const NextStepTODecoder = io.type({
  c: io.union([io.null, CardHtmlTODecoder]),
  s: studyStatTODecoder,
  progress: StepProgressTODecoder,
  msg: nullableString,
  streak: nullableNumber,
  mode: io.string,
});

export type NextStepTO = io.TypeOf<typeof NextStepTODecoder>;

export const nextStepTOResponseDecoder = responseDecoder<NextStepTO>(
  NextStepTODecoder,
);
