import React from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from '../../styles/components/card/CardWebView.module.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    justifyContent: 'flex-start',
    minHeight: '80px',
    fontSize: '34px',
    fontFamily: 'RobotoFlex',
    fontWeight: 400,
  },
}));

export interface CardWebViewProps {
  html: string;
  isSurveyMode?: boolean;
}

const CardWebView: React.FC<CardWebViewProps> = ({html, isSurveyMode}) => {
  const classes = useStyles();

  const prepareHtml = (str: string) => {
    let result = str
      .replaceAll('font-size: 22px;', 'font-size: 28px;')
      .replaceAll('h3', 'span')
      .replaceAll(
        'class="question-text"',
        'class="question-text" style=" color:#333333;"',
      );
    if (isSurveyMode)
      result = result.replaceAll(
        /[\s\n]color:\s{0,1}.{7,7}/g,
        ' color:#FFFFFF',
      );
    return result;
  };

  const makeInnerHtml = (str: string) => {
    return {__html: prepareHtml(str)};
  };

  // debug('HTML');
  // debug(html);

  const d = (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={makeInnerHtml(html)}
    />
  );

  return <div className={styles.cardContainer}>{d}</div>;
};

export default CardWebView;
