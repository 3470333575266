import React, {useMemo} from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';
import clsx from 'clsx';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '40%',
    backgroundColor: theme.palette.extended.progressBarBackground,
    borderRadius: '12px',
    alignSelf: 'stretch',
    height: '6px',
  },
  progress: {
    backgroundColor: '#C5C5C9',
  },
  roundedProgress: {
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
  },
  finishProgress: {
    borderRadius: '12px',
  },
}));

export interface LineProgressBarProps {
  steps: number;
  current: number;
}

const LineProgressBar: React.FC<LineProgressBarProps> = ({steps, current}) => {
  const classes = useStyles();
  const fullProgress = useMemo(() => current >= steps, [current, steps]);
  const progressWidth = useMemo(
    () =>
      steps == 0
        ? '0%'
        : fullProgress
        ? '100%'
        : `${(current * 100.0) / steps}%`,
    [fullProgress, steps, current],
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <span
          className={clsx(
            classes.progress,
            fullProgress ? classes.finishProgress : classes.roundedProgress,
          )}
          style={{width: progressWidth}}
        />
      </div>
    </div>
  );
};

export default LineProgressBar;
