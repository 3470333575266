import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';
import clsx from 'clsx';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    width: '360px',
    height: '60px',
    borderRadius: '12px',
    backgroundColor: theme.palette.extended.cardActionButtonHoverColor,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.extended.cardActionButtonHoverColor,
    },
    '&:active': {
      backgroundColor: theme.palette.extended.cardActionButtonHoverColor,
    },
    '&:disabled': {
      backgroundColor: theme.palette.extended.cardAnswerButtonColor,
      color: theme.palette.extended.authButtonDisabledColor,
    },
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontFamily: 'RobotoFlex',
  },
  signInButton: {
    fontSize: '20px',
    fontWeight: 700,
  },
}));

export interface AuthButtonProps {
  text: string;
  onClick: () => void;
  disabled: boolean;
  signInButton?: boolean;
}

const AuthButton: React.FC<AuthButtonProps> = ({
  text,
  onClick,
  disabled,
  signInButton
}) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(
        classes.root,
        signInButton ? classes.signInButton : null,
      )}
      onClick={onClick}
      {...{disabled}}>
      {text}
    </Button>
  );
};

export default AuthButton;
