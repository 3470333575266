import React, {useEffect, useMemo, useState} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import lottie, {AnimationItem, SVGRendererConfig} from 'lottie-web';
import {debug} from '../util/logger';
import {AnimationTypeEnum} from '../model/domain/enum/AnimationTypeEnum';
import { CSSProperties } from '@mui/material/styles/createTypography';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    backgroundColor: 'red',
    transitionProperty: 'background-color',
    transitionDuration: '2s',
    transitionTimingFunction: 'ease',
  },
}));

export interface BSAnimationProps {
  animationType: AnimationTypeEnum;
  seed: number;
  size: {width: number | undefined; height: number | undefined};
  onComplete: () => void;
}

const BSAnimation: React.FC<BSAnimationProps> = ({
  animationType,
  seed,
  size,
  onComplete,
}) => {
  const classes = useStyles();
  const animRef = React.useRef<HTMLDivElement>(null);

  const allAnimations = useMemo(
    () => ({
      Complete: [
        require('../assets/animations/complete/C_Circles.json'),
        require('../assets/animations/complete/C_Rotation.json'),
        require('../assets/animations/complete/C_Slashes.json'),
      ],
      Positive: [
        require('../assets/animations/positive/P_Correct.json'),
        require('../assets/animations/positive/P_Good-Job.json'),
        require('../assets/animations/positive/P_Nice.json'),
      ],
      Negative: [
        require('../assets/animations/negative/N_Oops.json'),
        require('../assets/animations/negative/N_Uh-oh.json'),
        require('../assets/animations/negative/N_Whoops.json'),
      ],
    }),
    [],
  );

  const animationData = useMemo(() => {
    const animations = allAnimations[animationType];
    const idx = seed % animations.length;
    return animations[idx];
  }, [allAnimations, seed, animationType]);

  useEffect(() => {
    if (animRef.current) {
      debug('text2');

      const animation: AnimationItem = lottie.loadAnimation({
        animationData: animationData,
        container: animRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
          // preserveAspectRatio: 'xMaxYMax meet',
          // preserveAspectRatio: 'xMinYMax meet',
          preserveAspectRatio: 'xMidYMid slice',
          // viewBoxOnly: true,
          // viewBoxSize: '0 0 3000 2000',
          // preserveAspectRatio: 'slice',
        } as SVGRendererConfig,
      });
      animation.addEventListener('complete', (e) => {
        debug('COMPLETE');
        onComplete();
        // setBg({
        //   backgroundColor: '#333333',
        // });
      });
    }
  }, [animRef, animationData, onComplete]);

  const style: CSSProperties = {
    // width: size.width ?? 500,

    height: size.height ?? 50,
    // height: '80%',
    // backgroundColor: 'white',
    // backgroundColor: '#333333',
    // height: 500,
    // display: 'flex',
    // flex: 10,
  };

  return (
    <div
      style={
        {
          // backgroundColor: '#333333',
          // display: 'flex',
          // flexDirection: 'row',
          // justifyContent: 'stretch',
        }
      }>
      <div ref={animRef} style={{...style}} />
    </div>
  );
};

export default BSAnimation;
