import React from 'react';
import { alpha, TextField, Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ToggleButton from '@mui/material/ToggleButton';
import {BSTheme, bsTypography} from '../../AppTheme';
import clsx from 'clsx';

export interface MultilineInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

const MultilineInput: React.FC<MultilineInputProps> = ({
  placeholder,
  value,
  onChange,
  isDisabled,
}) => {
  const useStyles = makeStyles((theme: BSTheme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      borderRadius: '12px',
      border: 'solid 1px ' + theme.palette.extended.inputBorderColor,
      position: 'relative',
      width: '500px',
      backgroundColor: theme.palette.extended.transparent,
      padding: '16px 16px',
      fontFamily: 'Barlow Semi Condensed',
      fontWeight: 400,
      fontSize: '16px',
      color: '#000000',
      '&.Mui-focused': {
        border: 'solid 2px ',
        borderColor: theme.palette.extended.cardActionButtonHoverColor
      }
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  }));
  const classes = useStyles();
  return (
    <TextField
      multiline
      disabled={isDisabled ?? false}
      rows={15}
      maxRows={15}
      variant="standard"
      placeholder={placeholder}
      InputProps={{classes}}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default MultilineInput;
