export const FieldTypeEnum = {
  Unknown: 0,
  Html: 1,
  Img: 2,
  WordArray: 3,
  WordSet: 4,
  Video: 5,
  ImgGif: 6,
  Audio: 7,
  YouTube: 8,
  SingleChoice: 9,
} as const;

export type FieldTypeEnum = typeof FieldTypeEnum[keyof typeof FieldTypeEnum];
