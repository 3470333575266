import * as O from 'fp-ts/lib/Option';
import {pipe} from 'fp-ts/function';

export const WeekDayEnum = {
  Sunday: 7,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;

export type WeekDayEnum = typeof WeekDayEnum[keyof typeof WeekDayEnum];

export const weekDayToString = (wd: WeekDayEnum) => {
  switch (wd) {
    case WeekDayEnum.Sunday:
      return 'Sunday';
    case WeekDayEnum.Monday:
      return 'Monday';
    case WeekDayEnum.Tuesday:
      return 'Tuesday';
    case WeekDayEnum.Wednesday:
      return 'Wednesday';
    case WeekDayEnum.Thursday:
      return 'Thursday';
    case WeekDayEnum.Friday:
      return 'Friday';
    case WeekDayEnum.Saturday:
      return 'Saturday';
    default:
      return '';
  }
};

export const parseWeekDayFromNullable = (
  a: number | null | undefined,
): O.Option<WeekDayEnum> =>
  pipe(
    O.fromNullable(a),
    O.filter((n: number) => n >= 1 && n <= 7),
    O.map((n: number) => n as WeekDayEnum),
  );
