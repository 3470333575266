import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

export const ThemeEnum = {
  Grey: 'grey',
} as const;

export type ThemeEnum = typeof ThemeEnum[keyof typeof ThemeEnum];

export const parseThemeFromNullable = (
  s: string | null | undefined,
): O.Option<ThemeEnum> =>
  pipe(
    O.fromNullable(s),
    O.map((t: string) => {
      switch (t) {
        case 'grey':
          return ThemeEnum.Grey;
        default:
          return ThemeEnum.Grey;
      }
    }),
  );
