import * as O from 'fp-ts/lib/Option';
import {pipe} from 'fp-ts/lib/function';
import {eqString} from 'fp-ts/lib/Eq';
import {CardHtml} from '../model/domain/CardHtml';
import {TmplField} from '../model/domain/TmplField';
import {FieldTypeEnum} from '../model/domain/enum/FieldTypeEnum';
import {QuestionTypeEnum} from '../model/domain/enum/QuestionTypeEnum';
import {compareSeq} from '../util/arrayUtil';
import { DeckTypeEnum } from '../model/domain/enum/DeckTypeEnum';

const toLoverCase = (a: string[]) => a.map((v) => v.toLowerCase());

const compareAnswers = (
  correctAnswers: O.Option<string[]>,
  rawAnswers: O.Option<string[]>,
  ignoreOrder: boolean,
): O.Option<boolean> => {
  if (O.isNone(correctAnswers)) {
    return O.none;
  } else {
    return pipe(
      correctAnswers,
      O.chain((correct: string[]) =>
        pipe(
          rawAnswers,
          O.map((raw: string[]) =>
            compareSeq(eqString)(
              toLoverCase(correct),
              toLoverCase(raw),
              ignoreOrder,
            ),
          ),
        ),
      ),
    );
  }
};

const checkAnswerCorrectness = (
  card: CardHtml,
  rawAnswers: O.Option<string[]>,
): O.Option<boolean> => {
  return pipe(
    card.answerTmplField,
    O.chain((t: TmplField) => {
      if (t.typ === FieldTypeEnum.WordArray) {
        return compareAnswers(t.l, rawAnswers, false);
      } else if (t.typ === FieldTypeEnum.WordSet) {
        return compareAnswers(t.l, rawAnswers, true);
      } else if (t.typ === FieldTypeEnum.SingleChoice) {
          return compareAnswers(t.l, rawAnswers, true);
      } else if (card.questionType === QuestionTypeEnum.TrueFalse) {
        return compareAnswers(t.v === ""? t.l : O.some([t.v]), rawAnswers, true);
      } else if (card.questionType === QuestionTypeEnum.Text) {
        return compareAnswers(t.v === ""? t.l : O.some([t.v.toLowerCase()]), rawAnswers, true);
      } else {
        return O.none;
      }
    }),
  );
};

const isSingleChoice = (card: CardHtml): boolean =>
  pipe(
    card.bFieldType,
    O.map((bFieldType: FieldTypeEnum) => {
      return bFieldType === FieldTypeEnum.SingleChoice;
    }),
    O.getOrElse<boolean>(() => false),
  );

const isTextMode = (card: CardHtml) =>
  card.questionType === QuestionTypeEnum.Text;

const isTrueFalse = (card: CardHtml): boolean =>
  card.questionType === QuestionTypeEnum.TrueFalse;

const isOrdered = (card: CardHtml): boolean =>
  pipe(
    card.answerTmplField,
    O.map((t: TmplField) => t.typ === FieldTypeEnum.WordArray),
    O.getOrElse<boolean>(() => false),
  );

const isSurvey = (card: CardHtml) =>
    card.questionType === QuestionTypeEnum.Survey || card.deckTyp === DeckTypeEnum.SurveyMode;

  const isSurveyMode = (card: CardHtml) =>
    card.deckTyp === DeckTypeEnum.SurveyMode;

export default {
  checkAnswerCorrectness,
  isSingleChoice,
  isTrueFalse,
  isOrdered,
  isSurvey,
  isTextMode,
  isSurveyMode
};
