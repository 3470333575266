import { useEffect, useCallback, useState } from 'react';
import { executeTask, BSTaskFromIO } from '../../util/bs-fp';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import {pipe} from 'fp-ts/lib/function';
import * as E from 'fp-ts/Either';
import { AsyncData } from '../AsyncData';
import { BSTask } from '../types';


  export function useAsyncState<T>(asyncFunction: () => BSTask<T>) {
    const [state, updateState] = useState<AsyncData<T>>({inProgress: false});
    const fetchTask = useCallback(() => {
        return pipe(
          BSTaskFromIO(() => updateState({inProgress: true})),
          TE.chain(() => asyncFunction()),
          T.map(E.fold((error) => {
            updateState({error, inProgress: false})
          }, (data) => {
            updateState({data, inProgress: false})
          })),
        );
      }, [updateState, asyncFunction]);

    useEffect(() =>{
      executeTask(fetchTask())
    },[fetchTask])
    return state;
  }
