import * as O from 'fp-ts/lib/Option';
import {NotificationTO} from '../to/NotificationTO';
import {BSNotification} from '../../model/domain/BSNotification';

const toDomain = (to: NotificationTO): BSNotification =>
  ({
    message: O.fromNullable(to.message),
  } as BSNotification);

export default {
  toDomain,
};
