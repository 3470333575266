import React, {useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/ChangePassword.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import {useNavigate} from 'react-router-dom';
import AuthButton from '../components/buttons/AuthButton';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import AuthService from '../service/AuthService';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';
import { BackButton } from './PasswordReset';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    paddingTop: '50px',
    backgroundColor: theme.palette.extended.authBackgroundColor,
  },
  errorMessage: {
    color: theme.palette.primary.dark,
  },
  signInButtonContainer: {
    marginTop: '50px'
  },
  passwordInput: {
    marginTop: '0px'
  }
}));

interface ChangePasswordProps {}

const ChangePassword: React.FC<ChangePasswordProps> = ({}) => {
  const themeStyles = useStyles();
  const navigate = useNavigate();

  const secret = useMemo(() => window.location.href.split('?')[1], []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onOk = (): void => {
    setInProgress(false);
    setErrorMessage('');
    navigate('/signin');
  };

  const onError = (e: BSError): void => {
    setInProgress(false);
  };

  const setToast = useError(true);

  const updatePasswordTask = () =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage('');
      }),
      TE.chain(() => AuthService.updatePassword(secret, password)),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );

  const onUpdatePassword = async () => {
    const task = updatePasswordTask();
    await executeTask(task);
  };

  const passwordNotMatchErr = 'Passwords do not match';

  useEffect(() => {
    document.title = 'Change Password – Blank Slate';
  }, []);
  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.titleContainer}>Change Password</div>
      <div className={clsx(styles.passwordInput, themeStyles.passwordInput)}>
        <AuthInput
          key={'newPassword'}
          placeholder={'New password'}
          secure={true}
          value={password}
          onChange={setPassword}
        />
      </div>
      <div className={styles.confirmPasswordInput}>
        <AuthInput
          key={'confirmPassword'}
          placeholder={'Retype new password'}
          secure={true}
          value={confirmPassword}
          onChange={(pws) => {
            setConfirmPassword(pws);
            setErrorMessage(password != pws ? passwordNotMatchErr : '');
          }}
        />
      </div>
      <div className={clsx(styles.signInButtonContainer, themeStyles.signInButtonContainer)}>
        <AuthButton
          text={'Save New Password'}
          onClick={onUpdatePassword}
          disabled={
            inProgress || password.length == 0 || password != confirmPassword
          }
        />
      </div>
      <div className={styles.messageContainer}>
        <span className={clsx(styles.errorMessage, themeStyles.errorMessage)}>
          {errorMessage}
        </span>
      </div>
      <BackButton />
    </div>
  );
};

export default ChangePassword;
