import { DeckCard} from "../../model/domain/Deck";
import { DeckDetailsTO } from "../to/DeckTO";

const toDomain = (to: DeckDetailsTO): DeckCard[] =>
  (to.map(deckDetailsEntry => ({
    id: deckDetailsEntry.id,
    name: deckDetailsEntry.name,
    content: deckDetailsEntry.content
  })) as DeckCard[]);

export default {
  toDomain,
};
