import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigation} from 'react-router-dom';
import BsLogoMenu from '../assets/images/bsLogoMenu.svg';
import homeIcon from '../assets/images/homeIcon.svg';
import performanceIcon from '../assets/images/performanceIcon.svg';
import deckIcon from '../assets/images/deckIcon.svg';
import settingsIcon from '../assets/images/settingsIcon.svg';
import logoutIcon from '../assets/images/logoutIcon.svg';
import tourIcon from '../assets/images/tourIcon.svg';
import feedbackIcon from '../assets/images/feedbackIcon.svg';
import * as O from 'fp-ts/Option';
import {useRecoilState, useRecoilValue} from 'recoil';
import {userState} from '../model/state/userState';
import {nextStepState} from '../model/state/nextStepState';
import {sideMenuForceOpen} from '../model/state/sideMenuState';
import menuIcon from '../assets/images/menu.svg';
import {pipe} from 'fp-ts/function';
import {User} from '../model/domain/User';
import clsx from 'clsx';
import CardService from '../service/CardService';
import {InformationPopup} from './InformationPopup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '1024px',
  },
  backgroundNormal: {
    background:
      'linear-gradient(90deg, rgba(213, 213, 213, 0.5) 0%, rgba(249, 249, 249, 0.50) 50%, rgba(213, 213, 213, 0.5) 100%)',
  },
  backgroundSurvey: {
    background: 'linear-gradient(360deg, #475361 0%, #2B353F 100%);',
  },
  menu: {
    position: 'sticky',
    top: '0',
    left: '0',
    height: '100vh',
    width: '200px',
    transition: 'all 1000ms',
  },
  menuHidden: {
    position: 'sticky',
    top: '0',
    left: '0',
    height: '100vh',
    width: '58px',
    transition: 'all 1000ms',
  },
  menuSpace: {
    backgroundColor: '#fff',
    minWidth: '200px',
    width: '200px',
    flexShrink: 0,
    transition: 'all 1s',
  },
  menuSpaceHidden: {
    backgroundColor: '#fff',
    minWidth: '58px',
    width: '58px',
    flexShrink: 0,
    transition: 'all 1s',
  },
  content: {
    width: '100%',
    overflowY: 'auto',
    minHeight: '100vh',
  },
  menuLogo: {
    paddingLeft: '10px',
    // paddingRight: '27px',
  },
  menuAndLogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflowY: 'hidden',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
  },
  upperMenuContainer: {
    paddingTop: '58px',
    paddingLeft: '9px',
    paddingRight: '9px',
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
  },
  lowerMenuContainer: {
    paddingLeft: '9px',
    paddingRight: '9px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  menuItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    borderRadius: '8px',
    paddingLeft: '8px',
  },
  menuItemSelectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    borderRadius: '8px',
    backgroundColor: '#DCDCDC80',
    paddingLeft: '8px',
  },
  menuItemName: {
    paddingLeft: '8px',
  },
  menuItemLink: {
    paddingTop: '8px',
    paddingBottom: '6px',
    color: '#000',
    textDecoration: 'none',
    fontSize: '15px',
    fontFamily: 'Inter',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemImage: {
    minWidth: '24px',
  },
  emptyIcon: {
    width: '24px',
  },
  logoutBlock: {
    marginTop: '10px',
    marginBottom: '200px',
    paddingLeft: '9px',
    paddingRight: '9px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  menuHeaderContainer: {
    paddingTop: '52px',
    paddingLeft: '9px',
    paddingRight: '27px',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  menuBack: {
    paddingLeft: '9px',
    paddingRight: '9px',
  },
}));

interface SideMenuButtonAttributes {
  name: string;
  link: string;
  paths?: string[];
  isDisabled: boolean;
  isInvisible?: boolean;
  icon?: string;
}

const lowerMenuButtons: SideMenuButtonAttributes[] = [
  {
    name: 'Tour',
    link: '/tour',
    isDisabled: false,
    icon: tourIcon,
  },
  {
    name: 'Feedback',
    link: '/feedback',
    isDisabled: false,
    icon: feedbackIcon,
  },
  {
    name: 'Settings',
    link: '/settings',
    paths: ['/updatePws', '/preferences'],
    isDisabled: false,
    icon: settingsIcon,
  },
  // {
  //   name: 'FAQ',
  //   link: '/faq',
  //   isDisabled: false,
  // },
];

const logoutMenuButton: SideMenuButtonAttributes = {
  name: 'Logout',
  link: '/logout',
  isDisabled: false,
  icon: logoutIcon,
};

interface SideMenuButtonProps {
  sideMenuButtonAttributes: SideMenuButtonAttributes;
  showMenu: boolean;
}

const SideMenuButton: React.FC<SideMenuButtonProps> = ({
  sideMenuButtonAttributes,
  showMenu,
}) => {
  const location = useLocation();
  const classes = useStyles();
  return (
    <div
      style={{
        pointerEvents: sideMenuButtonAttributes.isDisabled ? 'none' : undefined,
        opacity: sideMenuButtonAttributes.isDisabled ? '0.5' : undefined,
        width: showMenu ? '180px' : 'auto',
      }}
      className={
        location.pathname.includes(sideMenuButtonAttributes.link) ||
        sideMenuButtonAttributes.paths?.includes(location.pathname)
          ? classes.menuItemSelectedContainer
          : classes.menuItemContainer
      }>
      <Link to={sideMenuButtonAttributes.link} className={classes.menuItemLink}>
        <img
          className={classes.menuItemImage}
          alt={sideMenuButtonAttributes.name}
          src={sideMenuButtonAttributes.icon}
        />

        {showMenu && (
          <span className={classes.menuItemName}>
            {sideMenuButtonAttributes.name}
          </span>
        )}
      </Link>
    </div>
  );
};

type SideMenuContentProps = {
  showMenu: boolean;
};

const SideMenuContent: React.FC<SideMenuContentProps> = ({showMenu}) => {
  const classes = useStyles();
  const nextStep = useRecoilValue(nextStepState);
  const user = useRecoilValue(userState);
  const libraryEnabled = O.toNullable(user)?.partner.enableLibrary;
  const performanceEnabled = O.toNullable(user)?.partner.enableUserPerformance;
  const [forceMenuShow, setForceShowMenu] = useRecoilState(sideMenuForceOpen);
  const assessmentNotDone =
    !(O.toNullable(nextStep)?.mode === 'Review')
    && O.isSome(O.toNullable(nextStep)?.c || O.none)
    && O.toNullable(nextStep)?.mode !== 'Survey';

  const shownLibraryTour = pipe(
    user,
    O.map((u: User) => u.libraryTourShown),
    O.getOrElse<boolean>(() => false),
  );

  const upperMenuButtons: SideMenuButtonAttributes[] = [
    {
      name: "Today's Cards",
      link: '/welcomeBack',
      paths: ['/study'],
      isDisabled: false,
      icon: homeIcon,
    },
    {
      name: 'My Performance',
      link: '/performance',
      isDisabled: assessmentNotDone,
      icon: performanceIcon,
      isInvisible: !performanceEnabled,
    },
    {
      name: 'Library',
      link: shownLibraryTour ? '/library' : '/library_tour',
      isDisabled: assessmentNotDone,
      icon: deckIcon,
      isInvisible: !libraryEnabled,
    },
  ];

  return (
    <div className={classes.menuAndLogoContainer}>
      <div className={classes.menuHeaderContainer}>
        <div className={classes.menuBack}>
          <img
            onClick={() => {
              setForceShowMenu((old) => !old);
            }}
            alt="menu"
            src={menuIcon}
          />
        </div>
        {showMenu && (
          <Link to="/welcomeBack" className={classes.menuLogo}>
            <img alt="Blank Slate" src={BsLogoMenu} />
          </Link>
        )}
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.upperMenuContainer}>
          {upperMenuButtons
            .filter((btn) => !btn.isInvisible)
            .map((btn) => {
              return (
                <SideMenuButton
                  key={btn.name}
                  sideMenuButtonAttributes={btn}
                  showMenu={showMenu}
                />
              );
            })}
        </div>
        <div className={classes.lowerMenuContainer}>
          {lowerMenuButtons.map((btn) => {
            return (
              <SideMenuButton
                key={btn.name}
                sideMenuButtonAttributes={btn}
                showMenu={showMenu}
              />
            );
          })}
        </div>
        <div className={classes.logoutBlock}>
          <SideMenuButton
            sideMenuButtonAttributes={logoutMenuButton}
            showMenu={showMenu}
          />
        </div>
      </div>
    </div>
  );
};

interface SideMenuProps {
  children: JSX.Element;
}

export const SideMenu: React.FC<SideMenuProps> = ({children}) => {
  const user = useRecoilValue(userState);
  const isLoggedIn = useMemo(() => O.isSome(user), [user]);
  const nextStep = useRecoilValue(nextStepState);
  const location = useLocation();
  const pathName = location.pathname;
  const isAssessmentCompleted = O.isNone(
    O.toNullable(nextStep)?.c || O.some(false),
  );
  const [showSurveyModePopup, setShowSurveyModePopup] = useState<
    boolean | null
  >(null);
  const isSurvey = useMemo(() => {
    const step = O.toUndefined(nextStep);
    if (!step) return false;
    const card = O.toUndefined(step.c);
    if (!card) return false;
    return CardService.isSurveyMode(card) && pathName === '/study';
  }, [pathName, nextStep]);

  useEffect(() => {
    if (isSurvey && showSurveyModePopup === null) {
      setShowSurveyModePopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurvey]);

  const [forceShowMenu, setForceShowMenu] = useRecoilState(sideMenuForceOpen);
  const showMenu = isLoggedIn && forceShowMenu;
  useEffect(() => {
    if (isAssessmentCompleted) setForceShowMenu(true);
  }, [isAssessmentCompleted, setForceShowMenu]);

  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        isSurvey ? classes.backgroundSurvey : classes.backgroundNormal,
      )}>
      {showSurveyModePopup !== null && showSurveyModePopup && (
        <InformationPopup
          message="You're now entering Survey Mode! Before you finish for the day, we have a few extra questions. No one in your organization can see your answers to them. Survey Mode is 100% anonymous!"
          onClose={() => {
            setShowSurveyModePopup(false);
          }}
        />
      )}
      {isLoggedIn && (
        <div className={showMenu ? classes.menuSpace : classes.menuSpaceHidden}>
          <div className={showMenu ? classes.menu : classes.menuHidden}>
            <SideMenuContent showMenu={showMenu} />
          </div>
        </div>
      )}

      <div className={classes.content}>{children}</div>
    </div>
  );
};
