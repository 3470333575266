import React, {useCallback, useMemo, useState} from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardAnswerButton, {
  CardAnswerButtonProps,
} from '../buttons/CardAnswerButton';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import {pipe} from 'fp-ts/lib/function';
import {eqNumber} from 'fp-ts/Eq';
import ButtonsStandardLayout from './ButtonsStandardLayout';
import {inspect} from 'util';
import CardAnswerOptions from './CardAnswerOptions';
import {actionShortcuts, useShortcuts} from '../../hooks/useShortcuts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separatorVisible: {
    backgroundColor: '#999999',
    height: '1px',
    width: '400px',
    margin: '6px',
  },
  separatorInVisible: {
    display: 'flex',
    height: '1px',
    margin: '6px',
  },
}));

export interface OrderedChoiceButtonsGroupProps {
  options: string[];
  showCounterForSelectedOptions: boolean;
  showCounterForUnselectedOptions: boolean;
  onSelectionChanged: (selection: string[]) => void;
  disabled: boolean;
  showBadge: boolean;
  surveyMode?: boolean;
}

const OrderedChoiceButtonsGroup: React.FC<OrderedChoiceButtonsGroupProps> = ({
  options,
  showCounterForSelectedOptions,
  showCounterForUnselectedOptions,
  onSelectionChanged,
  disabled,
  showBadge,
  surveyMode = false,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<number[]>([]);

  const unselected = useMemo(
    () =>
      pipe(
        Array.from(Array(options.length).keys()),
        A.filter((a: number) => !A.elem(eqNumber)(a, selected)),
      ),
    [options, selected],
  );

  const buttonBuilder = useCallback(
    (selected: boolean) => (text: string, onClick: () => void) =>
      ({text, selected, onClick, disabled} as CardAnswerButtonProps),
    [disabled],
  );

  const selectedButtonBuilder = useMemo(
    () => buttonBuilder(true),
    [buttonBuilder],
  );
  const unselectedButtonBuilder = useMemo(
    () => buttonBuilder(false),
    [buttonBuilder],
  );

  const fireOnSelectionChanged = useCallback(
    (newSelection: number[]) => {
      const selectedOptions = pipe(
        newSelection,
        A.map((i: number) => A.lookup(i)(options)),
        A.compact,
      );
      onSelectionChanged(selectedOptions);
    },
    [options, onSelectionChanged],
  );

  const toggleSelection = useCallback(
    (idx: number) => {
      setSelected((oldSelected: number[]) => {
        const isAlreadySelected = A.elem(eqNumber)(idx, oldSelected);
        const newSelected = isAlreadySelected
          ? A.filter((a: number) => a !== idx)(oldSelected)
          : A.union(eqNumber)(selected, [idx]);
        fireOnSelectionChanged(newSelected);
        return newSelected;
      });
    },
    [selected, fireOnSelectionChanged],
  );

  useShortcuts(actionShortcuts, (index) => {
    [...selectedButtons, ...unselectedButtons][index].onClick();
  });

  const selectedButtons = useMemo(
    () =>
      pipe(
        selected.map((idx: number, pos: number) =>
          pipe(
            options,
            A.lookup(idx),
            O.map((s: string) =>
              selectedButtonBuilder(
                showCounterForSelectedOptions ? `${pos + 1}. ${s}` : s,
                () => toggleSelection(idx),
              ),
            ),
          ),
        ),
        A.compact,
      ),
    [
      options,
      selected,
      selectedButtonBuilder,
      toggleSelection,
      showCounterForSelectedOptions,
    ],
  );

  const unselectedButtons = useMemo(
    () =>
      pipe(
        unselected.map((idx: number, pos: number) =>
          pipe(
            options,
            A.lookup(idx),
            O.map((s: string) =>
              unselectedButtonBuilder(
                showCounterForUnselectedOptions ? `${pos + 1}. ${s}` : s,
                () => toggleSelection(idx),
              ),
            ),
          ),
        ),
        A.compact,
      ),
    [
      options,
      unselected,
      unselectedButtonBuilder,
      toggleSelection,
      showCounterForUnselectedOptions,
    ],
  );

  const separator = useMemo(() => {
    const style =
      selectedButtons.length > 0 && selectedButtons.length != options.length
        ? classes.separatorVisible
        : classes.separatorInVisible;
    return <div className={style} />;
  }, [classes, selectedButtons, options.length]);

  return (
    <div className={classes.root}>
      <ButtonsStandardLayout
        dark={surveyMode}
        showBadge={showBadge}
        buttons={selectedButtons}
      />
      {separator}
      <ButtonsStandardLayout
        dark={surveyMode}
        showBadge={showBadge}
        buttons={unselectedButtons}
        badgeIndexOffset={selectedButtons.length}
      />
    </div>
  );
};

export default OrderedChoiceButtonsGroup;
