import {Stat} from '../../model/domain/Stat';
import {StatTO} from '../to/StatTO';
import * as O from 'fp-ts/lib/Option';

const fromDomain = (d: Stat): StatTO =>
  ({
    id: d.id,
    uId: d.uId,
    dId: d.dId,
    cId: d.cId,
    dt: d.dt,
    tta: d.tta,
    ans: d.ans,
    rawAnswers: O.toUndefined(d.rawAnswers),
    mode: d.mode,
  } as StatTO);

export default {
  fromDomain,
};
