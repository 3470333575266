import React, {useState} from 'react';
import * as O from 'fp-ts/lib/Option';
import clsx from 'clsx';

import styles from '../styles/components/TextAnswerGroup.module.css';

interface TextAnswerGroupProps {
  onSelectionChanged: (selectedOption: O.Option<string>) => void;
  disabled: boolean;
  surveyMode: boolean;
}

export const TextAnswerGroup: React.FC<TextAnswerGroupProps> = ({
  onSelectionChanged,
  disabled,
  surveyMode = false,
}) => {
  const [currentAnswer, setCurrentAnswer] = useState<string>('');
  return (
    <div style={{margin: '50px auto 0 auto'}}>
      <input
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        className={clsx(styles.input, surveyMode ? styles.inputDark : styles.inputLight)}
        placeholder="Type your answer here"
        disabled={disabled}
        value={currentAnswer}
        onChange={(e) => {
          const newText = e.currentTarget.value;
          setCurrentAnswer(newText);
          onSelectionChanged(
            newText === '' ? O.none : O.some(newText.toLowerCase().trim()),
          );
        }}
      />
    </div>
  );
};
