import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';
import {arrayOfNumbers, nullableNumber, nullableString} from './commonTypes';
import {PartnerTODecoder} from './PartnerTO';

export const UserTODecoder = io.type({
  id: io.number,
  partner: PartnerTODecoder,
  email: io.string,
  status: io.number,
  typ: io.number,
  firstName: nullableString,
  lastName: nullableString,
  username: nullableString,
  remindDt: nullableNumber,
  remindDays: arrayOfNumbers,
  notifyEmail: io.boolean,
  notifyApp: io.boolean,
  libraryTourShown: io.boolean,
  schedulerType: io.number,
  ui: nullableString,
});

export type UserTO = io.TypeOf<typeof UserTODecoder>;

export const userTODecoder = responseDecoder<UserTO>(UserTODecoder);
