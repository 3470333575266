import React from 'react';
import styles from '../styles/components/PopupConfirmation.module.css';
import NextButton from './buttons/NextButton';

interface PopupConfirmationProps {
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const PopupConfirmation: React.FC<PopupConfirmationProps> = ({
  title,
  onCancel,
  onConfirm,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.modal}>
        <div className={styles.textBlock}>{title}</div>
        <div className={styles.buttonsBlock}>
          <NextButton text="Cancel" onClick={onCancel} />
          <NextButton
            destructive
            text="Yes, remove my account"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};
