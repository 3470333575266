import React, {useCallback, useMemo} from 'react';
import {AnswerEnum} from '../../model/domain/enum/AnswerEnum';
import {CardDifficultyMode} from '../../model/domain/enum/CardDifficultyMode';
import SingleChoiceButtonsGroup from './SingleChoiceButtonsGroup';
import * as O from 'fp-ts/Option';
import NextQuestionButton from '../buttons/NextQuestionButton';
import CardActionBox from './CardActionBox';
import {useShortcuts} from '../../hooks/useShortcuts';
import {pipe} from "fp-ts/lib/function";
import {debug} from '../../util/logger';

export interface CardDifficultyChoiceProps {
  mode: CardDifficultyMode;
  onAnswer: (answer: AnswerEnum) => void;
  nextQuestionButtonText: string;
  studyMode?: string;
}

const CardDifficultyChoice: React.FC<CardDifficultyChoiceProps> = ({
  mode,
  onAnswer,
  nextQuestionButtonText,
  studyMode,
}) => {
  const strToAnswerEnum = useCallback((str: String): O.Option<AnswerEnum> => {
    debug('The answer is: ' + str);
    switch (str) {
      case 'Easy':
        return O.some(AnswerEnum.Easy);
      case 'Hard':
        return O.some(AnswerEnum.Hard);
      case 'I got it wrong!':
      case 'Next Question':
        return O.some(AnswerEnum.Again);
      default:
        return O.none;
    }
  }, []);

  const fireOnChoice = useCallback(
    (choice: O.Option<string>) => {
      pipe(
        choice,
        O.chain(strToAnswerEnum),
        O.map((answer: AnswerEnum) => onAnswer(answer)),
      );
    },
    [onAnswer, strToAnswerEnum],
  );

  const answer = useMemo(
    () => (
      <CardActionBox text={''}>
        <NextQuestionButton
          onClick={() => onAnswer(AnswerEnum.Easy)}
          text={nextQuestionButtonText}
        />
      </CardActionBox>
    ),
    [onAnswer, nextQuestionButtonText],
  );

  const easyHardWrongContent = useMemo(
    () => (
      <CardActionBox text={'How did you find that?'}>
        <SingleChoiceButtonsGroup
          showBadge
          disabled={false}
          options={['Easy', 'Hard', 'I got it wrong!']}
          easyHardButtons={true}
          onSelectionChanged={fireOnChoice}
        />
      </CardActionBox>
    ),
    [fireOnChoice],
  );

  const answerTimeExceededContent = useMemo(
    () => (
      <CardActionBox
        text={'You took a long time to answer that, we’ll get back to it.'}>
        <NextQuestionButton
          onClick={() => onAnswer(AnswerEnum.Again)}
          text={nextQuestionButtonText}
        />
      </CardActionBox>
    ),
    [onAnswer, nextQuestionButtonText],
  );

  useShortcuts([' '], () => {
    if (
      mode === CardDifficultyMode.AnswerTimeExceeded ||
      mode === CardDifficultyMode.Wrong
    ) {
      onAnswer(AnswerEnum.Again);
    } else if (mode === CardDifficultyMode.Answer) {
      onAnswer(AnswerEnum.Easy);
    }
  });

  const wrongContent = useMemo(
    () => (
      <CardActionBox text={studyMode === 'library' ? '' : 'We’ll get back to that later.'}>
        <NextQuestionButton
          onClick={() => onAnswer(AnswerEnum.Again)}
          text={nextQuestionButtonText}
        />
      </CardActionBox>
    ),
    [onAnswer, nextQuestionButtonText, studyMode],
  );

  const contentMap = useMemo(() => {
    const m = new Map<string, JSX.Element>();
    m.set('Answer', answer);
    m.set('AnswerTimeExceeded', answerTimeExceededContent);
    m.set('Wrong', wrongContent);
    return m;
  }, [
    answer,
    wrongContent,
    answerTimeExceededContent,
  ]);

  const content = useMemo(() => contentMap.get(mode) || easyHardWrongContent, [
    contentMap,
    mode,
    easyHardWrongContent,
  ]);

  return <>{content}</>;
};

export default CardDifficultyChoice;
