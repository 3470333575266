import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';
import {
  nullableNumber,
  nullableString,
  nullableArrayOfStrings,
} from './commonTypes';

export const TmplFieldTODecoder = io.type({
  n: io.string,
  typ: io.number,
  v: io.string,
  l: nullableArrayOfStrings,
  //extra values/words
  el: nullableArrayOfStrings,
  w: nullableNumber,
  h: nullableNumber,
  title: nullableString,
  comment: nullableString,
});

export type TmplFieldTO = io.TypeOf<typeof TmplFieldTODecoder>;

export const tmplFieldTOResponseDecoder = responseDecoder<TmplFieldTO>(
  TmplFieldTODecoder,
);
