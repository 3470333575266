import {BSTask} from '../model/types';
import {pipe} from 'fp-ts/function';
import {debugIO} from '../util/logger';
import * as TE from 'fp-ts/TaskEither';
import FeedbackApi from '../api/FeedbackApi';

const sendFeedback = (message: string): BSTask<void> =>
  pipe(
    debugIO('Sending feedback'),
    TE.chain(() => FeedbackApi.sendFeedback(message)),
  );

export default {
  sendFeedback,
};
