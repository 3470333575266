import React from 'react';
import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'RobotoFlex',
    fontWeight: 700,
    fontSize: '35px',
    textTransform: 'uppercase',
    paddingBottom: '2em',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '30px',
    color: theme.palette.primary.main,
  },
}));

export interface MessageModel {
  title: string[];
  subtitle: string[];
}

export interface MessageBoxProps extends MessageModel {}

const MessageBox: React.FC<MessageBoxProps> = ({title, subtitle}) => {
  const classes = useStyles();
  const titleElements = title.map((t, idx) => (
    <div key={`t${idx}`} className={classes.title}>
      {t}
    </div>
  ));
  const subtitleElements = subtitle.map((t, idx) => (
    <div key={`st${idx}`} className={classes.subTitle}>
      {t}
    </div>
  ));
  return (
    <div className={classes.root}>
      {titleElements}
      {subtitleElements}
    </div>
  );
};

export default MessageBox;
