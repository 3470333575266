import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '../../assets/images/searchIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    padding: '9px 7px 9px 9px',
  },
  input: {
    width: '315px',
    outline: 'none',
    border: 'none',
    color: '#000',
    '&::placeholder': {
      color: '#005FDB',
      opacity: 0.49,
    },
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Inter',
    '&:disabled': {
      opacity: '1',
      backgroundColor: '#FFF',
    },
  },
  root: {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '15px',
    border: '1px solid #DBDBDB',
  },
}));

interface SearchInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  isDisabled = false,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        pointerEvents: isDisabled ? 'none' : undefined,
        opacity: isDisabled ? '0.6' : undefined,
      }}
      className={classes.root}>
      <img className={classes.icon} src={SearchIcon} alt="search" />
      <input
        disabled={isDisabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classes.input}
      />
    </div>
  );
};
