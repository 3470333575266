import React, {useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {agreementState} from '../model/state/agreementState';
import NextButton from './buttons/NextButton';
import styles from '../styles/components/Agreement.module.css';
import AuthService from '../service/AuthService';
import * as O from 'fp-ts/Option';
import {useNavigate} from 'react-router-dom';
import {userState} from '../model/state/userState';
import {executeTask} from '../util/bs-fp';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import AuthApi from '../api/AuthApi';
import {createPortal} from 'react-dom';
import {Iframe} from './Iframe';
import {useError} from '../model/state/errorState';

export const Agreement: React.FC = () => {
  const [agreement, setAgreement] = useRecoilState(agreementState);
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  const onSignOutError = () => {};
  const onSignOutOk = () => {
    setUser(O.none);
    setAgreement(undefined);
    navigate('/signin');
  };

  const setToast = useError(true);

  const sendSignOutRequestTask = () =>
    pipe(
      AuthService.signOut(),
      TE.mapLeft(setToast),
      T.map(E.fold(onSignOutError, onSignOutOk)),
    );

  const onAcceptAgreementError = () => {};
  const onAcceptAgreementOk = () => {
    setAgreement(undefined);
  };

  const sendAcceptAgreementRequestTask = () =>
    pipe(
      AuthApi.acceptAgreement(),
      TE.mapLeft(setToast),
      T.map(E.fold(onAcceptAgreementError, onAcceptAgreementOk)),
    );
  return (
    <>
      {agreement && (
        <div className={styles.agreement}>
          <div className={styles.agreementBlock}>
            <div className={styles.agreementFrame}>
              <div
                style={{
                  overflow: 'hidden',
                  height: '100%',
                  display: 'flex',
                }}>
                <Iframe
                  frameBorder="0"
                  scrolling="yes"
                  className={styles.frame}
                  src={agreement}
                />
              </div>
            </div>
            <div className={styles.agreementButtonsContainer}>
              <div className={styles.agreementButtonContainer}>
                <NextButton
                  buttonRounded
                  width="444px"
                  text="Got it"
                  onClick={async () => {
                    const task = sendAcceptAgreementRequestTask();
                    await executeTask(task);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
