import React, {CSSProperties} from 'react';
import styles from '../../styles/components/input/ToggleSwitch.module.css';

export interface ToggleSwitchProps {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  style?: CSSProperties;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  name,
  checked,
  onChange,
  style,
  disabled = false,
}) => {
  return (
    <label className={styles.checkboxGoogle} style={style}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <span className={styles.checkboxGoogleSwitch} />
      <span className={styles.label}>{name}</span>
    </label>
  );
};

export default ToggleSwitch;
