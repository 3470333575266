import React from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'block',
    minWidth: '300px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.extended.bannerTextColor,
    backgroundColor: theme.palette.extended.bannerBackgroundRedColor,
    marginBottom: '16px',
    marginTop: '16px',
    padding: '9px 12px',
    textAlign: 'center',
    border: 'solid 1px ' + theme.palette.extended.bannerBackgroundRedColor,
    borderRadius: '16px',
    maxHeight: '45px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export interface CardBannerProps {
  text: string;
}

const QuestionBanner: React.FC<CardBannerProps> = ({text}) => {
  const classes = useStyles();
  return <div className={classes.root}>{text}</div>;
};

export default QuestionBanner;
