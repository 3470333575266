import * as io from 'io-ts';
import { responseDecoder } from './TOEncoderDecoder';

export const DeckEntryTODecoder = io.type({
  deckId: io.string,
  deckName: io.string,
  tkl: io.union([io.number,io.null])
})

export const DeckTODecoder = io.array(DeckEntryTODecoder)

export type DeckTO = io.TypeOf<typeof DeckTODecoder>;

export const deckTOResponseDecoder = responseDecoder<DeckTO>(
  DeckTODecoder,
);

export const DeckCardTODecoder = io.type({
  id: io.string,
  name: io.string,
  content: io.string
})

export const DeckCardsTODecoder = io.array(DeckCardTODecoder)

export type DeckDetailsTO = io.TypeOf<typeof DeckCardsTODecoder>;

export const deckCardsTOResponseDecoder = responseDecoder<DeckDetailsTO>(
  DeckCardsTODecoder,
);

export const ResourcesItemTODecoder = io.type({
  id: io.string,
  title: io.string,
  url: io.string,
})

export const ResourcesItemsTODecoder = io.array(ResourcesItemTODecoder)

export type ResourcesListTO = io.TypeOf<typeof ResourcesItemsTODecoder>;

export const resourcesItemsTOResponseDecoder = responseDecoder<ResourcesListTO>(
  ResourcesItemsTODecoder,
);