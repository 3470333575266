import React from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardAnswerButton, {
  CardAnswerButtonProps,
} from '../buttons/CardAnswerButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  toggleElementWrapper: {
    margin: '14px 20px',
  },
}));

export interface ButtonsStandardLayoutProps {
  buttons: CardAnswerButtonProps[];
  showBadge: boolean;
  badgeIndexOffset?: number;
  readOnly?: boolean;
  easyHardButtons?: boolean;
  dark?: boolean;
}

const ButtonsStandardLayout: React.FC<ButtonsStandardLayoutProps> = ({
  buttons,
  showBadge,
  badgeIndexOffset = 0,
  readOnly = false,
  easyHardButtons,
  dark = false,
}) => {
  const classes = useStyles();
  const elements = buttons.map((p: CardAnswerButtonProps, idx: number) => {
    // const B: typeof CardAnswerButton = () => button;
    return (
      <div key={p.text} className={classes.toggleElementWrapper}>
        <CardAnswerButton
          index={showBadge ? idx + badgeIndexOffset : undefined}
          key={p.text}
          {...p}
          onClick={() => p.onClick()}
          readOnly={readOnly}
          easyHardButtons={easyHardButtons}
          dark={dark}
        />
      </div>
    );
  });

  return (
    <>
      <div className={classes.root}>{elements}</div>
    </>
  );
};

export default ButtonsStandardLayout;
