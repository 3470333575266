import RestService from './RestService';
import {pipe} from 'fp-ts/lib/function';
import {BSTask} from '../model/types';
import * as TE from 'fp-ts/lib/TaskEither';
import {UserTO} from './to/UserTO';
import UserConverter from './converter/UserConverter';
import {User} from '../model/domain/User';
import {BSTaskVoid} from '../util/bs-fp';
import {
  NotificationTO,
  notificationTOResponseDecoder,
} from './to/NotificationTO';
import NotificationConverter from './converter/NotificationConverter';
import TklStatConverter from './converter/TklStatConverter';
import { tklStatTOResponseDecoder } from './to/TklStatTO';

const updateSettings = (user: User): BSTask<void> => {
  const url = '/app/user/settings';
  const body: UserTO = UserConverter.fromDomain(user);
  return pipe(
    RestService.post(url, body),
    TE.chain(() => BSTaskVoid),
  );
};

const setLibraryTourShown = (): BSTask<void> => {
  const url = '/app/library/tour-shown';
  return pipe(
    RestService.put(url),
    TE.chain(() => BSTaskVoid),
  );
};

const greeting = () => {
  const url = '/app/user/greeting';
  return pipe(
    RestService.get(url),
    notificationTOResponseDecoder,
    TE.map(NotificationConverter.toDomain),
  );
};
const getTKLStat = () => {
  const url = '/app/user/tkl-stat';
  return pipe(
    RestService.get(url),
    tklStatTOResponseDecoder,
    TE.map(TklStatConverter.toDomain),
  );
};

const removeUser = (): BSTask<void> => {
  const url = '/app/user/delete';
  return pipe(
    RestService.sendDelete(url),
    TE.chain(() => BSTaskVoid),
  );
};

export default {
  updateSettings,
  setLibraryTourShown,
  greeting,
  getTKLStat,
  removeUser,
};
