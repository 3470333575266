import * as O from 'fp-ts/Option';
import {pipe} from 'fp-ts/function';
import {Eq} from 'fp-ts/Eq';
import * as S from 'fp-ts/Set';
import * as A from 'fp-ts/Array';

function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

function removeItemAtIndex<T>(arr: T[], index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export const optionToArray = (o: O.Option<string>): string[] => {
  return pipe(
    o,
    O.fold(
      () => [],
      (a: string) => [a],
    ),
  );
};

export const shuffleArray = <T extends unknown>(array: T[]): void => {
  if (array && array.length > 0) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }
};

const compareUnordered = <T>(E: Eq<T>) => (lhs: T[], rhs: T[]) => {
  const s1: Set<T> = S.fromArray(E)(lhs);
  const s2: Set<T> = S.fromArray(E)(rhs);
  const eq = S.getEq(E);
  return eq.equals(s1, s2);
};

const compareOrdered = <T>(E: Eq<T>) => (lhs: T[], rhs: T[]) => {
  const eq = A.getEq(E);
  return eq.equals(lhs, rhs);
};

export const compareSeq = <T>(E: Eq<T>) => (
  lhs: T[],
  rhs: T[],
  ignoreOrder: boolean,
) => {
  if (ignoreOrder) {
    return compareUnordered(E)(lhs, rhs);
  } else {
    return compareOrdered(E)(lhs, rhs);
  }
};

export const contains = <T>(E: Eq<T>) => (value: T, array: T[]): boolean =>
  A.elem(E)(value, array);
