import {StudyStat} from '../../model/domain/StudyStat';
import {StudyStatTO} from '../to/StudyStatTO';

const toDomain = (to: StudyStatTO): StudyStat =>
  ({
    n: to.n,
    l: to.l,
    r: to.r,
    done: to.done,
    tomorrow: to.tomorrow,
    ignore: to.ignore,
    hasAssignedCards: to.hasAssignedCards,
  } as StudyStatTO);

export default {
  toDomain,
};
