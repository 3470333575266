import React from "react";
import {formatPercent} from "../util/formatUtils";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  ringWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ringText: {
    position: 'absolute',
    fontSize: '24px',
    width: '100px',
    fontWeight: '500',
    fontFamily: 'Inter',
    lineHeight: '32px',
    textAlign: 'center',
  },
}));

interface RingProps {
  percent: number;
  size: number;
  strokeWidth: number;
}

export const RingBlock: React.FC<RingProps> = ({
  percent,
  size,
  strokeWidth,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.ringWrap}>
      <Ring percent={percent} size={size} strokeWidth={strokeWidth} />
      <div className={classes.ringText}>
        <span>{formatPercent(percent)}</span>
      </div>
    </div>
  );
};

export const Ring: React.FC<RingProps> = ({percent, size, strokeWidth}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = ((100 - percent) / 100) * circumference;
  let backgroundColor: string;
  let color: string;

  if (percent >= 80) {
    color = '#62B772';
    backgroundColor = '#62B7722B';
  } else if (percent < 65) {
    color = '#CD1515';
    backgroundColor = '#cd15151a';
  } else {
    color = '#FCD651';
    backgroundColor = '#fcd65133';
  }

  return (
    <svg width={size} height={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        stroke={backgroundColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={0}
        strokeLinecap="round"
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
    </svg>
  );
};
