import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {
  toastState,
  ToastError,
  errorState,
  notificationState,
} from '../model/state/errorState';
import ExclamationMark from '../assets/images/exclamationMark.svg';
import {BSError} from '../model/error/BSError';
import NextButton from './buttons/NextButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 120,
    left: 0,
    width: '100%',
  },
  inside: {
    width: '343px',
    margin: '0 auto',
    padding: '16px',
    backgroundColor: '#FFF',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '11px',
    alignItems: 'center',
    borderRadius: '15px',
    transition: 'all 500ms',
  },
  insideHidden: {
    transform: 'translate(0, 200px)',
    width: '343px',
    margin: '0 auto',
    padding: '16px',
    backgroundColor: '#FFF',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '11px',
    alignItems: 'center',
    borderRadius: '15px',
    transition: 'all 500ms',
  },
  text: {
    margin: '0px',
  },
  errorPageRoot: {
    display: 'flex',
    flex: '1 1',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorPageInside: {
    width: '424px',
    boxShadow:
      '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.95px 6.25px rgba(0, 0, 0, 0.19);',
    borderRadius: '13px',
    color: '#000',
    padding: '27px 27px 30px 27px',
  },
  errorPageTitle: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Roboto Condensed',
    textAlign: 'center',
  },
  errorPageText: {
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'Roboto Condensed',
    textAlign: 'center',
  },
  notificationPopupRoot: {
    position: 'absolute',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationPopupInside: {
    maxWidth: '487px',
    padding: '51px 36px 25px 36px',
    borderRadius: '5px',
    backgroundColor: '#FFF',
    boxShadow:
      '0px 1.95px 6.25px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px rgba(0, 0, 0, 0.039);',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 300,
    fontSize: '16px',
    color: '#000000',
  },
  notificationButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface ErrorPageProps {
  error: BSError;
  onReset: () => void;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({error}) => {
  const classes = useStyles();
  return (
    <div className={classes.errorPageRoot}>
      <div className={classes.errorPageInside}>
        <h1 className={classes.errorPageTitle}>{error.userFriendlyTitle}</h1>
        <p className={classes.errorPageText}>{error.userFriendlyExplanation}</p>
      </div>
    </div>
  );
};
interface ToastProps {
  error: ToastError;
  onReset: () => void;
}

const Toast: React.FC<ToastProps> = ({error, onReset}) => {
  const classes = useStyles();
  const errorMessage = error.text;
  const [visualShow, setVisualShow] = useState(false);
  useEffect(() => {
    setVisualShow(false);
    setTimeout(() => {
      setVisualShow(true);
      setTimeout(() => {
        setTimeout(() => {
          onReset();
        }, 500);
        setVisualShow(false);
      }, 2000);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return (
    <div className={classes.root}>
      <div className={visualShow ? classes.inside : classes.insideHidden}>
        <img alt="toast message" src={ExclamationMark} />
        <p className={classes.text}>{errorMessage}</p>
      </div>
    </div>
  );
};

interface NotificationPopupProps {
  message: string;
  onClose: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  message,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.notificationPopupRoot}>
      <div className={classes.notificationPopupInside}>
        <p className={classes.notificationText}>{message}</p>
        <div className={classes.notificationButtonContainer}>
          <NextButton text="Got it" onClick={onClose} buttonRounded={true}/>
        </div>
      </div>
    </div>
  );
};

interface ErrorInterrupterProps {
  children: JSX.Element;
}

export const ErrorInterrupter: React.FC<ErrorInterrupterProps> = ({
  children,
}) => {
  const [toast, setToast] = useRecoilState(toastState);
  const [notification, setNotification] = useRecoilState(notificationState);
  const [error, setError] = useRecoilState(errorState);
  const currentError = toast.length > 0 ? toast[0] : undefined;

  return (
    <>
      {typeof error !== 'undefined' ? (
        <ErrorPage error={error} onReset={() => setError(undefined)} />
      ) : (
        children
      )}
      {currentError && (
        <Toast
          onReset={() => {
            setToast((old) => old.slice(1));
          }}
          error={currentError}
        />
      )}
      {notification && (
        <NotificationPopup
          message={notification}
          onClose={() => {
            setNotification(undefined);
          }}
        />
      )}
    </>
  );
};
