import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

interface IframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  children?: JSX.Element;
}

export const Iframe: React.FC<IframeProps> = (props) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef}>
      {mountNode && createPortal(props.children, mountNode)}
    </iframe>
  );
};
