import {Button, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {boolean} from 'fp-ts';
import {ButtonBadge} from './ButtonBadge';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    maxWidth: '444px',
    width: '100%',
    height: '60px',
    borderRadius: '12px',
    backgroundColor: '#434343',
  },
  buttonHalfSized: {
    width: '222px',
    height: '40px',
    borderRadius: '12px',
    backgroundColor: '#434343',
  },
  destructive: {
    backgroundColor: '#FF0000 !important',
  },
  buttonRounded: {
    width: '152px',
    height: '50px',
    borderRadius: '100px',
    backgroundColor: '#1C1C1E',
  },
  textButtonRounded: {
    fontWeight: 700,
    fontSize: '22px',
    color: theme.palette.secondary.main,
    fontFamily: 'Roboto',
  },
  text: {
    fontWeight: 700,
    fontSize: '22px',
    color: theme.palette.secondary.main,
  },
}));

export interface NextButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  destructive?: boolean;
  halfSized?: boolean;
  buttonRounded?: boolean;
  shortcutBadge?: string;
  width?: string;
}

const NextButton: React.FC<NextButtonProps> = ({
  text,
  onClick,
  disabled,
  destructive = false,
  halfSized = false,
  buttonRounded = false,
  shortcutBadge = undefined,
  width = undefined,
}) => {
  const classes = useStyles();
  const buttonClass = halfSized ? classes.buttonHalfSized : classes.button;
  return (
    <Button
      variant="contained"
      style={{width: width}}
      className={clsx(
        buttonRounded ? classes.buttonRounded : buttonClass,
        destructive && classes.destructive,
      )}
      onClick={onClick}
      disabled={disabled}>
      <span
        className={buttonRounded ? classes.textButtonRounded : classes.text}>
        {text}
      </span>
      {shortcutBadge && <ButtonBadge text={shortcutBadge} />}
    </Button>
  );
};

export default NextButton;
