import {Badge} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ToggleButton from '@mui/material/ToggleButton';
import {BSTheme} from '../../AppTheme';
import {useCallback} from 'react';
import {actionShortcuts} from '../../hooks/useShortcuts';
import {ButtonBadge} from './ButtonBadge';
import clsx from 'clsx';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    minWidth: '200px',
    borderRadius: '12px',
    border: '1px solid #807F84',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    '&.Mui-selected': {
      backgroundColor: '#D6D6D6',
      fontWeight: 500,
    },
    '&:hover': {
      backgroundColor: theme.palette.extended.cardAnswerButtonHoverColor,
      fontWeight: 500,
    },
    '&:active': {
      backgroundColor: theme.palette.extended.cardAnswerButtonHoverColor,
      fontWeight: 500,
    },
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23.44px',
    color: '#434343',
  },
  darkRoot: {
    minWidth: '200px',
    height: '48px',
    borderRadius: '12px',
    border: '1px solid #807F83',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
      fontWeight: 700,
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#FFF',
      fontWeight: 700,
    },
    '&:hover': {
      backgroundColor: '#FFF',
      fontWeight: 700,
      color: '#000',
    },
    '&:active': {
      backgroundColor: '#FFF',
      color: '#000',
      fontWeight: 700,
    },
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23.44px',
    color: '#FFF',
  },
  easyHardButtons: {
    minWidth: '130px',
  },
}));

export interface CardAnswerButtonProps {
  text: string;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
  index?: number;
  readOnly?: boolean;
  easyHardButtons?: boolean;
  dark?: boolean;
}

const CardAnswerButton: React.FC<CardAnswerButtonProps> = ({
  text,
  selected,
  onClick,
  disabled,
  index,
  readOnly = false,
  easyHardButtons,
  dark = false,
}) => {
  const classes = useStyles();

  const handleOnPress = useCallback(() => {
    if (!(disabled ?? false)) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <ToggleButton
      {...{selected}}
      value={selected}
      className={clsx(
        dark ? classes.darkRoot : classes.root,
        easyHardButtons && classes.easyHardButtons,
      )}
      onClick={handleOnPress}
      style={{
        pointerEvents: readOnly ? 'none' : undefined,
      }}>
      <span style={{whiteSpace: 'normal'}}>{text}</span>
      {typeof index !== 'undefined' &&
        index < actionShortcuts.length &&
        typeof actionShortcuts[index] !== 'undefined' && (
          <ButtonBadge text={actionShortcuts[index]} />
        )}
    </ToggleButton>
  );
};

// export type CardAnswerButtonType = ReactElement<CardAnswerButtonProps>;

export default CardAnswerButton;
