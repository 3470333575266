import React, {useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/UpdatePassword.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import {useNavigate} from 'react-router-dom';
import AuthButton from '../components/buttons/AuthButton';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import AuthService from '../service/AuthService';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import {useRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {User} from '../model/domain/User';
import {BSError} from '../model/error/BSError';
import {useError} from '../model/state/errorState';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {},
  errorMessage: {
    color: theme.palette.primary.main,
  },
}));

interface UpdatePasswordProps {}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({}) => {
  const themeStyles = useStyles();
  const navigate = useNavigate();

  const [user] = useRecoilState(userState);
  const userEmail = useMemo(
    () =>
      pipe(
        user,
        O.map((u: User) => u.email),
        O.getOrElse(() => ''),
      ),
    [user],
  );

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onOk = (): void => {
    setInProgress(false);
    setErrorMessage('Password successfully updated');
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const onError = (e: BSError): void => {
    setInProgress(false);
  };

  const setToast = useError(true);

  const updatePasswordTask = () =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage('');
      }),
      TE.chain(() =>
        AuthService.updPasswordWithCurrent(
          userEmail,
          newPassword,
          currentPassword,
        ),
      ),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );
  const onUpdatePassword = async () => {
    const task = updatePasswordTask();
    await executeTask(task);
  };

  useEffect(() => {
    document.title = 'Change Password – Blank Slate';
  }, []);

  const passwordNotMatchErr = 'Passwords do not match';

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.titleContainer}>Change Password</div>
      <div className={styles.passwordInput}>
        <AuthInput
          key={'currentPassword'}
          placeholder={'current password...'}
          secure={true}
          value={currentPassword}
          onChange={setCurrentPassword}
        />
      </div>
      <div className={styles.passwordInput}>
        <AuthInput
          key={'newPassword'}
          placeholder={'new password...'}
          secure={true}
          value={newPassword}
          onChange={setNewPassword}
        />
      </div>
      <div className={styles.confirmPasswordInput}>
        <AuthInput
          key={'confirmPassword'}
          placeholder={'retype new password...'}
          secure={true}
          value={confirmPassword}
          onChange={(pws) => {
            setConfirmPassword(pws);
            setErrorMessage(newPassword != pws ? passwordNotMatchErr : '');
          }}
        />
      </div>
      <div className={styles.signInButtonContainer}>
        <AuthButton
          text={'Set New Password'}
          onClick={onUpdatePassword}
          disabled={
            inProgress ||
            newPassword.length == 0 ||
            newPassword != confirmPassword ||
            currentPassword.length == 0
          }
        />
      </div>
      <div className={styles.messageContainer}>
        <span className={clsx(styles.errorMessage, themeStyles.errorMessage)}>
          {errorMessage}
        </span>
      </div>
    </div>
  );
};

export default UpdatePassword;
