import {CardHtmlTO} from '../to/CardHtmlTO';
import {CardHtml} from '../../model/domain/CardHtml';
import TmplFieldConverter from './TmplFieldConverter';
import {QuestionTypeEnum} from '../../model/domain/enum/QuestionTypeEnum';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import {TmplField} from '../../model/domain/TmplField';
import {pipe} from 'fp-ts/lib/function';
import {FieldTypeEnum} from '../../model/domain/enum/FieldTypeEnum';
import * as io from "io-ts";

const toFieldTypeEnum = (a: number | null) =>
  pipe(
    a,
    O.fromNullable,
    O.map((x: number) => x as FieldTypeEnum),
  );

const toDomain = (to: CardHtmlTO): CardHtml => {
  const fl = to.fl.map(TmplFieldConverter.toDomain);
  return {
    cId: to.cId,
    dId: to.dId,
    f: to.f,
    b: to.b,
    deckTyp: to.deckTyp,
    questionTmplField: A.findFirst((a: TmplField) => a.n === 'question')(fl),
    answerTmplField: A.findFirst((a: TmplField) => a.n === 'answer')(fl),
    dueDt: to.dueDt,
    fFieldType: toFieldTypeEnum(to.fFieldType),
    bFieldType: toFieldTypeEnum(to.bFieldType),
    bComment: O.fromNullable(to.bComment),
    questionType: to.questionType as QuestionTypeEnum,
    questionBanner: O.fromNullable(to.questionBanner),
    questionLabel: O.fromNullable(to.questionLabel),
    deck: to.deck,
  } as CardHtml;
};

export default {
  toDomain,
};
