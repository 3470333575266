import React, {useCallback, useEffect, useState} from "react";
import StudyService from "../../service/StudyService";
import {useAsyncState} from "../../model/state/asyncState";
import {useShortcuts} from "../../hooks/useShortcuts";
import {ErrorPage} from "../ErrorInterrupter";
import {LoadingFallback} from "../progress/LoadingFallback";
import {CardReview} from "./CardReview";
import {AsyncData} from "../../model/AsyncData";
import {DeckEntry} from "../../model/domain/Deck";

interface CardDetailsProps {
  deckState: AsyncData<DeckEntry[]>;
  cardIds: string[];
  onClose: () => void;
}

export const CardDetails: React.FC<CardDetailsProps> = ({deckState, cardIds, onClose}) => {
  const [currentCard, setCurrentCard] = useState(0);

  const getState = useCallback(() => {
    return StudyService.getUserLibraryCard(cardIds[currentCard]);
  }, [cardIds, currentCard]);

  const state = useAsyncState(getState);

  const setNextCard = () => {
    if (cardIds.length - 1 > currentCard) {
      setCurrentCard((old) => old + 1);
    } else {
      onClose();
    }
  };

  useShortcuts([' '], setNextCard);

  useEffect(() => {
    document.title = 'Library Card – Blank Slate';
  }, []);

  if (state.error) return <ErrorPage error={state.error} onReset={() => {}} />;

  return (
    <div>
      {state.inProgress || typeof state.data === 'undefined' ? (
        <LoadingFallback />
      ) : (
        <CardReview
          isLast={cardIds.length - 1 <= currentCard}
          card={state.data}
          deckName={deckState.data?.find((deck) => Number(deck.deckId) == state.data?.dId)?.deckName}
          onNext={setNextCard}
          cardsQuantity={cardIds.length}
          currentCard={currentCard}
        />
      )}
    </div>
  );
};
