import styles from '../../styles/pages/SignUp.module.css';
import clsx from 'clsx';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  errorMessage: {
    color: theme.palette.extended.errorColor,
  },
  infoMessage: {
    color: theme.palette.extended.notificationColor,
  },
}));

type FieldWithValidationProps = {
  children: React.ReactNode;
  errorMessage: string;
  infoMessage?: string;
};

export const FieldWithValidation: React.FC<FieldWithValidationProps> = ({
  children,
  errorMessage,
  infoMessage,
}) => {
  const themeStyles = useStyles();

  return (
    <>
      {children}
      {errorMessage && (
        <div className={styles.messageContainer}>
          <p
            className={clsx(
              styles.errorMessage,
              themeStyles.errorMessage,
              styles.errorMessageError,
            )}>
            {errorMessage}
          </p>
        </div>
      )}
      {!errorMessage && infoMessage && (
        <div className={styles.messageContainer}>
          <p
            className={clsx(
              styles.errorMessage,
              themeStyles.infoMessage,
              styles.errorMessageInfo,
            )}>
            {infoMessage}
          </p>
        </div>
      )}
    </>
  );
};
