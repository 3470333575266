import { createTheme, Theme, adaptV4Theme } from '@mui/material';
import { Palette } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';

export interface BSPalette extends Palette {
  extended: {
    transparent: string;
    authBackgroundColor: string;
    progressBarBackground: string;
    authButtonActiveBackground: string;
    correctColor: string;
    incorrectColor: string;
    cardAnswerButtonBackground: string;
    cardAnswerButtonColor: string;
    cardAnswerButtonHoverColor: string;
    cardActionButtonColor: string;
    cardActionButtonHoverColor: string;
    authButtonDisabledColor: string;
    inputBorderColor: string;
    preferencesSectionTitle: string;
    bannerTextColor: string;
    bannerBackgroundRedColor: string;
    sigInInputBorderColor: string;
    sigInInputBorderColorDisabled: string;
    libraryTourProgress: string;
    errorColor: string;
    notificationColor: string;
  };
}

export interface BSTypography {
  textInput: CSSProperties;
}

export interface BSTheme extends Theme {
  palette: BSPalette;
}

export const greyTheme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      light: '#434343',
      main: '#333333',
      dark: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
    extended: {
      transparent: '#00000000',
      authBackgroundColor: '#F4F4F6',
      progressBarBackground: '#E3E3E8',
      authButtonActiveBackground: '#434343',
      correctColor: '#7ECE90',
      incorrectColor: '#ff0000',
      cardAnswerButtonBackground: '#00000000',
      cardAnswerButtonColor: '#807f84',
      cardAnswerButtonHoverColor: '#dadada',
      cardActionButtonColor: '#9a9a9a',
      cardActionButtonHoverColor: '#1C1C1E',
      authButtonDisabledColor: '#e5e5e5',
      inputBorderColor: '#c7ccce',
      preferencesSectionTitle: '#888888',
      bannerTextColor: '#000',
      bannerBackgroundRedColor: '#C6D0E9',
      sigInInputBorderColor: '##767677',
      sigInInputBorderColorDisabled: '#bebebe',
      libraryTourProgress: '#8CA6FB',
      errorColor: '#A8071A',
      notificationColor: '#848484',
    },
    action: {
      disabled: '#9a9a9a',
    },
  },
  typography: {
    button: {
      fontFamily: 'Barlow Semi Condensed',
      textTransform: 'none',
    },
  },
} as BSTheme));

export const bsTypography: BSTypography = {
  textInput: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
  },
};
