import { ResourcesItem } from '../../model/domain/Deck';
import { ResourcesListTO } from '../to/DeckTO';

const toDomain = (to: ResourcesListTO): ResourcesItem[] =>
  (to.map(resourcesItem => ({
    id: resourcesItem.id,
    title: resourcesItem.title,
    url: resourcesItem.url,
  })) as ResourcesItem[]);

export default {
  toDomain,
};
