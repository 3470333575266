import {UserTO} from '../to/UserTO';
import {User} from '../../model/domain/User';
import {UserTypeEnum} from '../../model/domain/enum/UserTypeEnum';
import * as O from 'fp-ts/lib/Option';
import {SchedulerTypeEnum} from '../../model/domain/enum/SchedulerTypeEnum';
import {parseThemeFromNullable} from '../../model/domain/enum/ThemeEnum';
import PartnerConverter from './PartnerConverter';
import {pipe} from 'fp-ts/function';
import {Hour} from '../../model/domain/Hour';

const remindTime2interval = (time?: number): number => {
  if (!time) return -1;
  const offset = new Date().getTimezoneOffset() / 60;
  const h = time - offset;
  if (h < 0) {
    return h + 24;
  } else if (h > 23) {
    return h - 24;
  } else {
    return h;
  }
};

const interval2remindTime = (hour: number | null): number | null => {
  if (hour === null || hour === undefined || hour < 0) return null;
  const offset = new Date().getTimezoneOffset() / 60;
  const time = hour + offset;
  if (time < 0) {
    return time + 24;
  } else if (time > 23) {
    return time - 24;
  } else {
    return time;
  }
};

const toDomain = (to: UserTO): User =>
  ({
    id: to.id,
    partner: PartnerConverter.toDomain(to.partner),
    email: to.email,
    status: to.status,
    typ: to.typ as UserTypeEnum,
    firstName: O.fromNullable(to.firstName),
    lastName: O.fromNullable(to.lastName),
    username: O.fromNullable(to.username),
    remindDt: pipe(
      O.fromNullable(to.remindDt),
      O.map(remindTime2interval),
      O.map((a) => a as Hour),
    ),
    remindDays: to.remindDays,
    notifyEmail: to.notifyEmail,
    libraryTourShown: to.libraryTourShown,
    notifyApp: to.notifyApp,
    schedulerType: to.schedulerType as SchedulerTypeEnum,
    theme: parseThemeFromNullable(to.ui),
  } as User);

const fromDomain = (d: User): UserTO =>
  ({
    id: d.id,
    partner: PartnerConverter.fromDomain(d.partner),
    email: d.email,
    status: d.status,
    typ: d.typ,
    firstName: O.toNullable(d.firstName),
    lastName: O.toNullable(d.lastName),
    username: O.toNullable(d.username),
    remindDt: interval2remindTime(O.toNullable(d.remindDt)),
    remindDays: d.remindDays,
    notifyEmail: d.notifyEmail,
    libraryTourShown: d.libraryTourShown,
    notifyApp: d.notifyApp,
    schedulerType: d.schedulerType,
    ui: O.toNullable(d.theme),
  } as UserTO);

export default {
  toDomain,
  fromDomain,
};
