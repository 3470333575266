import React, {useEffect, useMemo, useState} from 'react';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CardHtml} from '../../model/domain/CardHtml';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import {pipe} from 'fp-ts/lib/function';
import {TmplField} from '../../model/domain/TmplField';
import CardService from '../../service/CardService';
import CardWebView from './CardWebView';
import CardAnswerOptions from './CardAnswerOptions';
import CardTitle from './CardTitle';
import CardActionBox from './CardActionBox';
import ShowAnswerButton from '../buttons/ShowAnswerButton';
import QuestionBanner from './QuestionBanner';
import {useShortcuts} from '../../hooks/useShortcuts';
import questionBanner from './QuestionBanner';
import {shuffleArray} from '../../util/shuffle';
import {QuestionTypeEnum} from "../../model/domain/enum/QuestionTypeEnum";

const useStyles = makeStyles((theme: Theme) => ({
  cardFrontContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    itemsAlign: 'center',
    marginTop: '2em',
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    marginBottom: '1rem',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginBottom: '0px',
      alignItems: 'flex-end',
    },
  },
  buttonBlockItem: {
    width: '15rem',
    textAlign: 'center',
  },
  improveLink: {
    visibility: 'hidden',
    fontSize: '20px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#000',
    paddingTop: '1.9rem',
  },
}));

export interface CardFrontProps {
  cardCounter: number;
  card: CardHtml;
  isLoading: boolean;
  onAnswer: (rawAnswers: string[]) => void;
}

const CardFront: React.FC<CardFrontProps> = ({
  cardCounter,
  card,
  onAnswer,
  isLoading,
}) => {
  const classes = useStyles();

  const isSurvey = useMemo(() => CardService.isSurvey(card), [card]);
  const isSurveyMode = useMemo(() => CardService.isSurveyMode(card), [card]);
  const isTextMode = useMemo(() => CardService.isTextMode(card), [card]);
  const [rawAnswers, setRawAnswers] = useState<string[]>([]);

  useShortcuts([' '], () => {
    if (isShowAnswerButtonVisible) onAnswer(rawAnswers);
  });

  const correctOptions = useMemo(
    () =>
      pipe(
        card.answerTmplField,
        O.chain((b: TmplField) => b.l),
        O.getOrElse<string[]>(() => []),
      ),
    [card.answerTmplField],
  );

  const incorrectOptions = useMemo(
    () =>
      pipe(
        card.answerTmplField,
        O.chain((b: TmplField) => b.el),
        O.getOrElse<string[]>(() => []),
      ),
    [card.answerTmplField],
  );

  const allOptions = useMemo(() => {
    const all = correctOptions.concat(incorrectOptions);
    return card.questionType !== QuestionTypeEnum.MultipleChoiceOrdered ? shuffleArray(all) : all;
  }, [correctOptions, incorrectOptions, card]);

  const singleChoice = useMemo(() => CardService.isSingleChoice(card), [card]);
  const isTrueFalse = useMemo(() => CardService.isTrueFalse(card), [card]);

  const ordered = useMemo(() => CardService.isOrdered(card), [card]);

  const isShowAnswerButtonVisible = useMemo(() => {
    return (A.isEmpty(allOptions) && !isTrueFalse) || A.isNonEmpty(rawAnswers);
  }, [isTrueFalse, allOptions, rawAnswers]);

  const title = '';
  //isSurvey
  //   ? ''
  //   : A.isNonEmpty(allOptions) || isTrueFalse
  //   ? 'Are you certain? Click and find out:'
  //   : 'Think of the answer and click\nbelow to see if you’ve got it right!';

  const buttonText = isSurvey ? 'Next' : 'Show Answer';

  useEffect(() => {
    setRawAnswers([]);
  }, [cardCounter]);

  return (
    <>
      {card.deck && <QuestionBanner text={card.deck} />}
      {/* <CardTitle text={'question:'} labelText={card.questionLabel} /> */}
      <div className={classes.cardFrontContent}>
        <CardWebView isSurveyMode={isSurveyMode} html={card.f} />
        <CardAnswerOptions
          cardCounter={cardCounter}
          disabled={false}
          showCounterForSelectedOptions={true}
          showCounterForUnselectedOptions={false}
          trueFalse={isTrueFalse}
          textMode={isTextMode}
          singleChoice={singleChoice}
          ordered={ordered}
          options={isTrueFalse ? ['TRUE', 'FALSE'] : allOptions}
          onSelectionChanged={setRawAnswers}
          surveyMode={isSurveyMode}
          showBadge
        />
      </div>
      <div className={classes.buttonBlock}>
        <div className={classes.buttonBlockItem}></div>
        <div
          style={{
            visibility: isShowAnswerButtonVisible ? 'visible' : 'hidden',
          }}>
          <CardActionBox text={title}>
            <ShowAnswerButton
              disabled={isLoading}
              text={buttonText}
              onClick={() => onAnswer(rawAnswers)}
            />
          </CardActionBox>
        </div>
        <a
          target="_blank"
          className={`${classes.buttonBlockItem} ${classes.improveLink}`}
          href="https://blankslatetech.typeform.com/feedback">
          Help Improve / Blank Slate
        </a>
      </div>
    </>
  );
};

export default CardFront;
