import {useCallback, useEffect} from 'react';

export const actionShortcuts = [
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'z',
  'x',
  'c',
  'v',
];

export const useShortcuts = (
  keys: string[],
  callback: (idx: number) => void,
  modifiers: {
    ctrl: boolean;
    alt: boolean;
    shift: boolean;
    meta: boolean;
  } = {
    ctrl: false,
    alt: false,
    shift: false,
    meta: false,
  },
) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        if (
          modifiers.ctrl === event.ctrlKey &&
          modifiers.alt === event.altKey &&
          modifiers.shift === event.shiftKey &&
          modifiers.meta === event.metaKey
        ) {
          event.preventDefault();
          callback(actionShortcuts.findIndex((item) => item === event.key));
        }
      }
    },
    [keys, callback, modifiers],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
