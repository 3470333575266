import React, {CSSProperties} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import {Partner} from '../model/domain/Partner';
import AppConfig from './../AppConfig';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {},
  logo: {
    maxWidth: '200px',
    paddingRight: '54px',
    maxHeight: '56px',
  },
}));

export interface PartnerLogoProps {
  partner: Partner;
  style?: CSSProperties;
}

const PartnerLogo: React.FC<PartnerLogoProps> = ({partner, style}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)} {...{style}}>
      <a tabIndex={-1} target="blank" href={partner.url}>
        <img
          className={classes.logo}
          src={AppConfig.apiUrl + '/app/media/' + partner.logoId}
          alt=""
        />
      </a>{' '}
    </div>
  );
};

export default PartnerLogo;
