import React, {useCallback, useMemo, useState} from 'react';
import * as A from 'fp-ts/Array';
import {pipe} from 'fp-ts/lib/function';
import {eqNumber} from 'fp-ts/Eq';
import {CardAnswerButtonProps} from '../buttons/CardAnswerButton';
import ButtonsStandardLayout from './ButtonsStandardLayout';
import {actionShortcuts, useShortcuts} from '../../hooks/useShortcuts';

export interface MultipleChoiceButtonsGroupProps {
  options: string[];
  onSelectionChanged: (selection: string[]) => void;
  disabled: boolean;
  showBadge: boolean;
  surveyMode?: boolean;
}

const MultipleChoiceButtonsGroup: React.FC<MultipleChoiceButtonsGroupProps> = ({
  options,
  onSelectionChanged,
  disabled,
  showBadge,
  surveyMode = false,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const isSelected = useCallback(
    (idx: number): boolean => A.elem(eqNumber)(idx, selected),
    [selected],
  );

  const buttonBuilder = useCallback(
    (selected: boolean) => (text: string, onClick: () => void) =>
      ({text, selected, onClick, disabled} as CardAnswerButtonProps),
    [disabled],
  );

  const selectedButtonBuilder = useMemo(
    () => buttonBuilder(true),
    [buttonBuilder],
  );
  const unselectedButtonBuilder = useMemo(
    () => buttonBuilder(false),
    [buttonBuilder],
  );

  const fireOnSelectionChanged = useCallback(
    (newSelection: number[]) => {
      const selectedOptions = pipe(
        newSelection,
        A.map((i: number) => A.lookup(i)(options)),
        A.compact,
      );
      onSelectionChanged(selectedOptions);
    },
    [options, onSelectionChanged],
  );

  const toggleSelection = useCallback(
    (idx: number) => {
      const alreadySelected = A.elem(eqNumber)(idx, selected);
      const newSelection = alreadySelected
        ? A.filter((a: number) => a !== idx)(selected)
        : A.union(eqNumber)(selected, [idx]);
      setSelected(newSelection);
      fireOnSelectionChanged(newSelection);
    },
    [selected, setSelected, fireOnSelectionChanged],
  );

  useShortcuts(actionShortcuts, toggleSelection);

  const buttons = useMemo(
    () =>
      options.map((text: string, idx: number) =>
        isSelected(idx)
          ? selectedButtonBuilder(text, () => toggleSelection(idx))
          : unselectedButtonBuilder(text, () => toggleSelection(idx)),
      ),
    [
      isSelected,
      toggleSelection,
      options,
      selectedButtonBuilder,
      unselectedButtonBuilder,
    ],
  );

  return (
    <ButtonsStandardLayout
      dark={surveyMode}
      showBadge={showBadge}
      buttons={buttons}
    />
  );
};

export default MultipleChoiceButtonsGroup;
