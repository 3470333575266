
// eslint-disable-next-line no-restricted-syntax
export enum BSErrorType {
  AccountError,
  ApiError,
  InvalidResponseFormatError,
  ServiceUnavailableError,
  UnexpectedError,
  UpdateError,
  PasswordRequirementsNotMetError,
  ConflictError,
}

export type BSError = {
  type: BSErrorType,
  userFriendlyTitle: string,
  userFriendlyExplanation: string,
}
