import React from 'react';
import {Hour} from '../../model/domain/Hour';
import {addHours, addMinutes, format} from 'date-fns';
import * as O from 'fp-ts/Option';
import DropDown, {DropDownItem} from './DropDown';
import {pipe} from 'fp-ts/lib/function';

interface BSHourPickerProps {
  value: O.Option<Hour>;
  onChange: (value: O.Option<Hour>) => void;
  disabled?: boolean;
}

const BSHourPicker = ({
  value,
  onChange,
  disabled = false,
}: BSHourPickerProps) => {
  const values1to24: number[] = Array.from(
    {length: 24},
    (_, index) => index + 1,
  );

  const hourToUSFormat = (hours: number) => {
    const d0 = addHours(new Date(0), hours);
    const d = addMinutes(d0, d0.getTimezoneOffset());
    return format(d, 'h a');
  };

  const items: DropDownItem[] = values1to24.map(
    (h: number) =>
      ({
        value: h,
        text: h == 0 ? '' : hourToUSFormat(h),
      } as DropDownItem),
  );

  return (
    <>
      <DropDown
        disabled={disabled}
        items={items}
        value={pipe(
          value,
          O.map((value: Hour) => value as number),
          O.getOrElse(() => 0),
        )}
        onChange={(v: unknown) => {
          if (v === 0) {
            onChange(O.none);
          } else {
            onChange(O.some(v as Hour));
          }
        }}
      />
    </>
  );
};

export default BSHourPicker;
