import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CardAnswerButtonProps} from '../buttons/CardAnswerButton';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import {pipe} from 'fp-ts/lib/function';
import {eqNumber} from 'fp-ts/Eq';
import ButtonsStandardLayout from './ButtonsStandardLayout';
import {actionShortcuts, useShortcuts} from '../../hooks/useShortcuts';

export interface SingleChoiceButtonsGroupProps {
  options: string[];
  onSelectionChanged: (selectedOption: O.Option<string>) => void;
  disabled: boolean;
  showBadge: boolean;
  easyHardButtons?: boolean;
  surveyMode?: boolean;
}

const SingleChoiceButtonsGroup: React.FC<SingleChoiceButtonsGroupProps> = ({
  options,
  onSelectionChanged,
  disabled,
  showBadge,
  easyHardButtons,
  surveyMode = false,
}) => {
  const [selection, setSelection] = useState<O.Option<number>>(O.none);

  const isSelected = useCallback(
    (idx: number): boolean => O.elem(eqNumber)(idx, selection),
    [selection],
  );

  const fireOnSelectionChanged = useCallback(
    (newSelection: O.Option<number>) => {
      const selectedOption = pipe(
        newSelection,
        O.chain((index: number) => pipe(options, A.lookup(index))),
      );
      onSelectionChanged(selectedOption);
    },
    [options, onSelectionChanged],
  );

  const toggleSelection = useCallback(
    (idx: number) => {
      const newSelection = isSelected(idx) ? O.none : O.some(idx);
      setSelection(newSelection);
      fireOnSelectionChanged(newSelection);
    },
    [isSelected, setSelection, fireOnSelectionChanged],
  );

  useShortcuts(actionShortcuts, toggleSelection);

  const buttonBuilder = useCallback(
    (selected: boolean) => (text: string, onClick: () => void) =>
      ({text, selected, onClick, disabled} as CardAnswerButtonProps),
    [disabled],
  );

  const selectedButtonBuilder = useMemo(
    () => buttonBuilder(true),
    [buttonBuilder],
  );
  const unselectedButtonBuilder = useMemo(
    () => buttonBuilder(false),
    [buttonBuilder],
  );

  const buttons = useMemo(
    () =>
      options.map((text: string, idx: number) =>
        isSelected(idx)
          ? selectedButtonBuilder(text, () => toggleSelection(idx))
          : unselectedButtonBuilder(text, () => toggleSelection(idx)),
      ),
    [
      options,
      isSelected,
      toggleSelection,
      unselectedButtonBuilder,
      selectedButtonBuilder,
    ],
  );

  return (
    <ButtonsStandardLayout
      showBadge={showBadge}
      buttons={buttons}
      easyHardButtons={easyHardButtons}
      dark={surveyMode}
    />
  );
};

export default SingleChoiceButtonsGroup;
