import {atom, selector} from 'recoil';
import * as O from 'fp-ts/Option';
import {Option} from 'fp-ts/Option';
import {User} from '../domain/User';

export const userState = atom<Option<User>>({
  key: 'userState',
  default: O.none,
});

export const isLoggedInState = selector({
  key: 'isLoggedInState',
  get: ({get}) => {
    const userInfo = get(userState);
    return O.isSome(userInfo);
  },
});
