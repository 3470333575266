import React from 'react';
import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '80px',
  },
  messageContainer: {
    fontFamily: 'RobotoFlex',
    fontWeight: 400,
    fontSize: '24px',
    color: theme.palette.primary.light,
    marginBottom: '22px',
  },
  innerContainer: {},
}));

export interface CardActionBoxProps {
  text: string;
  children: JSX.Element;
}

const CardActionBox: React.FC<CardActionBoxProps> = ({text, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.messageContainer}>{text}</div>
      <div className={classes.innerContainer}>{children}</div>
    </div>
  );
};

export default CardActionBox;
