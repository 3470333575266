import * as io from 'io-ts';
import { arrayOfStrings } from './commonTypes';
import { responseDecoder } from './TOEncoderDecoder';

export const TklTeamStatTODecoder = io.type({
  id: io.number,
  name: io.string,
  tkl: io.number,
  topUsers: arrayOfStrings,
})

export const TklStatTODecoder = io.type({
  myTkl: io.number,
  myTeamTkl: io.number,
  topUsers: arrayOfStrings,
  topTeams: io.array(TklTeamStatTODecoder),
  topUsersTkl: io.array(io.array(io.union([io.string, io.number]))),
  totalCards: io.number,
  avgSessionTime: io.number,
  avgSessionCards: io.number,
  mostDifficultCards: io.array(io.string),
});

export type TklStatTO = io.TypeOf<typeof TklStatTODecoder>;

export const tklStatTOResponseDecoder = responseDecoder<TklStatTO>(
  TklStatTODecoder,
);
