/* eslint-disable no-restricted-syntax */
import { atom, useSetRecoilState } from "recoil";
import { BSError } from "../error/BSError";

export interface ToastError {
  text: string;
  type: ToastType;
}

export const notificationState = atom<string | undefined>({
  key: 'notificationState',
  default: undefined,
});

export const toastState = atom<ToastError[]>({
  key: 'toastState',
  default: [],
});

export const errorState = atom<BSError | undefined>({
  key: 'errorState',
  default: undefined,
});

export enum ToastType {
 info,
 warning,
 error
}

export const useError = (showInToast: boolean = false): (error: BSError) => BSError => {
  const setToast = useSetRecoilState(toastState)
  const setError = useSetRecoilState(errorState)
  return showInToast ? (e: BSError) => {
    setToast((old) => {
      return [...old, {text: e.userFriendlyExplanation, type: ToastType.error}]
    });
    return e
} : (e: BSError) => {
  setError(e);
  return e
}
}