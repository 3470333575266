import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/Tour.module.css';
import NextButton from '../components/buttons/NextButton';
import SkipButton from '../components/buttons/SkipButton';
import DashProgressBar from '../components/progress/DashProgressBar';
import LineProgressBar from '../components/progress/LineProgressBar';
import MessageBox, {MessageModel} from '../components/MessageBox';
import {useNavigate} from 'react-router-dom';

interface TourProps {}

const Tour: React.FC<TourProps> = ({}) => {
  const messages = useMemo<MessageModel[]>(() => {
    return [
      {
        title: ['Welcome to Blank Slate!'],
        subtitle: [
          'We help you effortlessly retain and utilize what you learn',
        ],
      },
      {
        title: [],
        subtitle: ['We focus on the forgetting part of learning.'],
      },
      {
        title: [],
        subtitle: [
          'We schedule information for review',
          'when you are likely to forget it.',
        ],
      },
      {
        title: ['IMPROVING RECALL LIKE THIS BOOSTS:'],
        subtitle: ['Creativity, Problem Solving Abilities and Confidence!'],
      },
      {
        title: ['PERFECTLY IN SYNC.'],
        subtitle: ['All of your reviews sync between devices automatically.'],
      },
      {
        title: ['that’s it!'],
        subtitle: [
          'Set your preferences by clicking on',
          'Settings in the menu on the left.',
        ],
      },
    ];
  }, []);

  const navigate = useNavigate();
  const totalSteps = useMemo(() => messages.length, [messages]);
  const [step, setStep] = useState(1);
  const isFinalStep = useMemo(() => totalSteps === step, [totalSteps, step]);

  const MessageContent = useCallback(() => {
    if (step < 1 || step > messages.length) {
      return null;
    }
    const message = messages[step - 1];
    return <MessageBox title={message.title} subtitle={message.subtitle} />;
  }, [step, messages]);

  const goToWelcomeBack = useCallback(() => {
    navigate('/welcomeBack');
  }, [navigate]);

  const onNextStep = useCallback(() => {
    if (isFinalStep) {
      goToWelcomeBack();
    } else {
      setStep(step + 1);
    }
  }, [isFinalStep, goToWelcomeBack, step]);

  const onSkip = useCallback(() => {
    goToWelcomeBack();
  }, [goToWelcomeBack]);

  useEffect(() => {
    document.title = 'Tour – Blank Slate';
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.messageContent}>
          <MessageContent />
        </div>
        <div className={styles.dashProgressBar}>
          <DashProgressBar steps={totalSteps} current={step} />
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonContainer}>
            <NextButton
              text={isFinalStep ? 'Get Started!' : 'Next'}
              onClick={onNextStep}
            />
          </div>
          {isFinalStep ? null : <SkipButton onClick={onSkip} />}
        </div>
      </div>
      <div className={styles.footer}>
        <LineProgressBar steps={totalSteps} current={step} />
      </div>
    </div>
  );
};

export default Tour;
