import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {BSTheme} from '../../AppTheme';

interface ButtonBadgeProps {
  text?: string;
}

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'none',
    position: 'absolute',
    right: -5,
    bottom: -12,
    background: 'none rgba(218,218,218,1)',
    borderRadius: '24px',
    border: '1px solid',
    borderColor: theme.palette.extended.cardAnswerButtonColor,
    padding: '0 5px 0 5px',
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    fontSize: '12px',
    color: theme.palette.extended.cardAnswerButtonColor,
    letterSpacing: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

export const ButtonBadge: React.FC<ButtonBadgeProps> = ({text}) => {
  const classes = useStyles();
  return (
    <>
      {typeof text !== 'undefined' && (
        <div className={classes.root}>{text.toUpperCase()}</div>
      )}
    </>
  );
};
