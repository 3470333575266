import {atom, useRecoilState, useRecoilValue} from 'recoil';
import { TklStat } from '../domain/TklStat';
import { boolean } from 'io-ts';
import { useEffect, useCallback } from 'react';
import { executeTask, BSTaskFromIO } from '../../util/bs-fp';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import {pipe} from 'fp-ts/lib/function';
import UserService from '../../service/UserService';
import * as E from 'fp-ts/Either';
import { useError } from './errorState';
import { BSError } from '../error/BSError';
import { AsyncData } from '../AsyncData';


const tklStatsState = atom<AsyncData<TklStat>>({
    key: 'tklStatsState',
    default: {inProgress: false},
  });

  export const useStatsState = () => {
    const [state, updateState] = useRecoilState(tklStatsState);
    const fetchTask = useCallback(() => {
        return pipe(
          BSTaskFromIO(() => updateState({inProgress: true})),
          TE.chain(() => UserService.getTKLStat()),
          // TE.chain(stat => updateState({stat, inProgress: false})),
          // TE.mapLeft(setError),
          T.map(E.fold((error) => {
            updateState({error, inProgress: false})
          }, (data) => {
            updateState({data, inProgress: false})
          })),
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [updateState]);
    
    useEffect(() =>{
      executeTask(fetchTask())
    },[fetchTask])
    return state;
  }
