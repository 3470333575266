import React, {useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {userState} from '../model/state/userState';
import * as O from 'fp-ts/Option';
import AppConfig from '../AppConfig';
import {pipe} from "fp-ts/lib/function";

declare global {
  interface Window { dataLayer: any; }
}

export interface AnalyticsProps {}

const Analytics: React.FC<AnalyticsProps> = ({}) => {
  const user = useRecoilValue(userState);
  const enableExternalAnalytics = pipe(
    user,
    O.fold(() => false, u => u.partner.enableExternalAnalytics),
  );
  const scriptId = 'ga-gtm';

  const initializeScript = () => {
    const {head} = document;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    let script = document.createElement('script');
    script.id = scriptId;
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${AppConfig.googleTagManager}`;
    head.insertBefore(script, head.firstChild);
  }

  const createIframe = () => {
    const {body} = document;
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.cssText = 'display:none;visibility:hidden';
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${AppConfig.googleTagManager}`;
    noscript.insertBefore(iframe, noscript.firstChild);
    body.insertBefore(noscript, body.firstChild);
  }

  useEffect(() =>{
    if (enableExternalAnalytics && !document.getElementById(scriptId)) {
      initializeScript();
      createIframe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default Analytics;