import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/PasswordReset.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import AuthButton from '../components/buttons/AuthButton';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import AuthService from '../service/AuthService';
import {useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import {Link} from 'react-router-dom';
import ArrowBackBlack from '../assets/images/arrowLeftBlack.svg';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    paddingTop: '87px',
    backgroundColor: theme.palette.extended.authBackgroundColor,
  },
  errorMessage: {
    color: theme.palette.primary.dark,
  },
  backLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    marginTop: '60px',
    marginBottom: '20px'
  },
  backLink: {
    color: '#1C1C1E',
    fontWeight: 500,
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: 'RobotoFlex'
  },
}));

interface PasswordResetProps {}

const PasswordReset: React.FC<PasswordResetProps> = ({}) => {
  const themeStyles = useStyles();

  const setUser = useSetRecoilState(userState);

  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onOk = (): void => {
    setInProgress(false);
    setErrorMessage(
      'Password reset email was sent.\n' +
        'Click the link in the email to reset your password.',
    );
    setUser(O.none);
  };

  const onError = (e: BSError): void => {
    setInProgress(false);
  };

  const setToast = useError(true);

  const sendResetPasswordRequest = () =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage('');
      }),
      TE.chain(() => AuthService.resetPassword(email)),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );

  const onResetPassword = async () => {
    const task = sendResetPasswordRequest();
    await executeTask(task);
  };

  useEffect(() => {
    document.title = 'Reset Password – Blank Slate';
  }, []);

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.titleContainer}>Reset password</div>
      <div className={styles.signInButtonContainer}>
        <AuthInput
        key={'email'}
        placeholder={'Enter your email'}
        secure={false}
        value={email}
        onChange={setEmail}
      />
      </div>
      <div className={styles.signInButtonContainer}>
        <AuthButton
          text={'Reset Password'}
          onClick={onResetPassword}
          disabled={inProgress || email.length == 0}
        />
      </div>
      <div className={styles.messageContainer}>
        <span className={clsx(styles.errorMessage, themeStyles.errorMessage)}>
          {errorMessage}
        </span>
      </div>
      <BackButton />
    </div>
  );
};

export const BackButton: React.FC = () => {
  const themeStyles = useStyles();
  return (
    <div className={themeStyles.backLinkContainer}>
      <img alt="back" src={ArrowBackBlack} />
      <Link className={themeStyles.backLink} to="/">
        Back to Sign In
      </Link>
    </div>
  );
};

export default PasswordReset;
