import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/Feedback.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthButton from '../components/buttons/AuthButton';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import MultilineInput from '../components/input/MultilineInput';
import FeedbackService from '../service/FeedbackService';
import MessageBox from '../components/MessageBox';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {},
  errorMessage: {
    color: theme.palette.primary.main,
  },
  thanks: {
    color: theme.palette.primary.main,
  },
}));

interface FeedbackProps {}

const Feedback: React.FC<FeedbackProps> = ({}) => {
  const themeStyles = useStyles();

  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const thanksMsgTitle = 'Thank you for writing.';
  const thanksSubtitle = 'We appreciate your feedback and your perspective.';

  const onOk = (): void => {
    setInProgress(false);
    setErrorMessage('');
    setMessage('');
    setIsSent(true);
  };

  const onError = (e: BSError): void => {
    setInProgress(false);
  };

  const setToast = useError(true);

  const sendFeedbackTask = () =>
    pipe(
      BSTaskFromIO(() => {
        setInProgress(true);
        setErrorMessage('');
      }),
      TE.chain(() => FeedbackService.sendFeedback(message)),
      TE.mapLeft(setToast),
      T.map(E.fold(onError, onOk)),
    );
  const onFeedback = async () => {
    const task = sendFeedbackTask();
    await executeTask(task);
  };

  useEffect(() => {
    document.title = 'Feedback – Blank Slate';
  }, []);

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      {isSent ? (
        <>
          <div style={{paddingBottom: '100px'}}>
            <MessageBox title={[thanksMsgTitle]} subtitle={[thanksSubtitle]} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.titleContainer}>Feedback</div>
          <div className={styles.feedbackMessageContainer}>
            <MultilineInput
              key="feedback"
              placeholder="Your feedback"
              value={message}
              onChange={(msg) => setMessage(msg)}
            />
          </div>
          <div className={styles.signInButtonContainer}>
            <AuthButton
              text={'Send'}
              onClick={onFeedback}
              disabled={inProgress || message.length == 0}
            />
          </div>
        </>
      )}

      <div className={styles.messageContainer}>
        <span className={clsx(styles.errorMessage, themeStyles.errorMessage)}>
          {errorMessage}
        </span>
      </div>
    </div>
  );
};

export default Feedback;
