/* eslint-disable no-restricted-syntax */
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import StudyService from '../service/StudyService';
import SortIcon from '../assets/images/sortIcon.svg';
import {Link} from 'react-router-dom';
import {SearchInput} from '../components/input/SearchInput';
import {notificationState} from '../model/state/errorState';
import {NoData} from '../components/NoData';
import {LoadingFallback} from '../components/progress/LoadingFallback';
import RoundExclamationMark from '../assets/images/roundExclamationMark.svg';
import {useAsyncState} from '../model/state/asyncState';
import {ErrorPage} from '../components/ErrorInterrupter';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '44px',
    paddingRight: '48px',
    fontFamily: 'Lato',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '-15px',
    height: '88vh',
  },
  head: {
    fontFamily: 'Roboto',
  },
  title: {
    color: '#000',
    margin: 0,
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '29px',
  },
  titleDescription: {
    color: '#000',
    margin: 0,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    marginTop: '10px',
  },
  search: {
    marginTop: '49px',
  },
  main: {
    marginTop: '18px',
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingTop: '29px',
    paddingBottom: '33px',
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
    flex: 1,
  },
  sort: {
    backgroundColor: '#E0E0E066',
    padding: '9px 21px 9px 21px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sortItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Lato',
  },
  sortButton: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  sortItemText: {
    margin: '0 5px 0 0',
    fontWeight: 700,
  },
  items: {
    paddingLeft: '2px',
    paddingRight: '12px',
    overflowY: 'auto',
  },
  externalResourcesItems: {
    paddingLeft: '2px',
    paddingRight: '12px',
    overflowY: 'auto',
  },
  itemText: {
    color: '#2D3540',
    margin: '0px',
  },
  itemLink: {
    color: '#2D3540',
    textDecoration: 'none',
  },
  resourcesItemLink: {},
  itemButton: {
    border: 'none',
    background: 'none',
    width: '100%',
    cursor: 'pointer',
    fontFamily: 'Lato',
  },
  itemContainer: {
    paddingLeft: '21px',
    paddingRight: '21px',
    paddingBottom: '2px',
    paddingTop: '3px',
    borderBottom: '2px solid #0000001A',
    '&:hover': {
      borderBottom: '1px solid #0000001A',
      paddingBottom: '3px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paragraph: {
    margin: '10px 0',
  },
  deckDetailsActionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    fontFamily: 'Roboto Condensed',
  },
  resourcesButton: {
    marginTop: '40px',
    paddingTop: '8px',
    paddingBottom: '7px',
    backgroundColor: '#193b6733',
    boxShadow: '6px 6px 54px rgba(0, 0, 0, 0.05)',
    filter:
      'drop-shadow(0px 1.85px 6.25px rgba(0, 0, 0, 0.19)) drop-shadow(0px 0.5px 1.75px rgba(0, 0, 0, 0.039));',
    width: '141px',
    borderRadius: '100px',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resourcesButtonText: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 500,
    margin: '0px',
    fontFamily: 'RobotoFlex',
  },
  hintRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  hintImage: {
    width: '18px',
    height: '18px',
  },
  hintButton: {
    width: '18px',
    height: '18px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '28px',
    paddingBottom: '28px',
  },
  button: {
    width: '220px',
    height: '48px',
    backgroundColor: '#191919',
    color: 'white',
    borderRadius: '12px',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 700,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '7px',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#E8E8E8',
      borderRadius: '100px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#7A7A7A',
      borderRadius: '100px',
    },
  },
}));

export enum SortOrder {
  nameForward,
  nameReverse,
  tklForward,
  tklReverse,
}

interface SortItemProps {
  name: string;
  onClick: () => void;
  disabled?: boolean;
}

export const SortItem: React.FC<SortItemProps> = ({
  name,
  onClick,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <button
      disabled={disabled}
      className={classes.sortButton}
      type="button"
      onClick={onClick}>
      <div className={classes.sortItem}>
        <p className={classes.sortItemText}>{name}</p>
        <img alt="sort" src={SortIcon} />
      </div>
    </button>
  );
};

interface HeadProps {
  title: string;
  text: string;
}

export const Head: React.FC<HeadProps> = ({title, text}) => {
  const classes = useStyles();
  return (
    <div className={classes.head}>
      <h1 className={classes.title}>{title}</h1>
      <p className={classes.titleDescription}>{text}</p>
    </div>
  );
};

export const DeckList: React.FC = () => {
  const classes = useStyles();
  const state = useAsyncState(StudyService.getUserLibraryDecks);
  const resourcesState = useAsyncState(StudyService.getUserLibraryResources);
  const [sort, setSort] = useState<SortOrder>(SortOrder.nameForward);
  const [searchString, setSearchString] = useState('');

  const searchResult = useMemo(() => {
    if (!state.data) return [];
    if (typeof searchString === 'undefined' || searchString === '')
      return state.data;
    return state.data.filter((card) => {
      const id = card.deckId.toLowerCase();
      const name = card.deckName.toLowerCase();
      const search = searchString.toLowerCase();
      return id.includes(search) || name.includes(search);
    });
  }, [state, searchString]);

  const sortedDecks = useMemo(() => {
    if (!searchResult) return [];
    const mutable = [...searchResult];
    return mutable.sort((a, b) => {
      const deckAName = a.deckName.toLowerCase();
      const deckBName = b.deckName.toLowerCase();
      const tklA = a.tkl || 0;
      const tklB = b.tkl || 0;
      switch (sort) {
        case SortOrder.nameForward:
          return deckAName > deckBName ? 1 : -1;
        case SortOrder.nameReverse:
          return deckAName > deckBName ? -1 : 1;
        case SortOrder.tklForward:
          return tklA - tklB;
        case SortOrder.tklReverse:
          return tklB - tklA;
      }
    });
  }, [searchResult, sort]);

  const setNotification = useSetRecoilState(notificationState);

  useEffect(() => {
    document.title = 'Library Deck List – Blank Slate';
  }, []);

  if (state.error) return <ErrorPage error={state.error} onReset={() => {}} />;

  return (
    <div className={classes.root}>
      <Head title="Content Decks" text="Click on any deck to review" />
      <div></div>
      <div className={classes.search}>
        <SearchInput
          isDisabled={state.inProgress}
          onChange={(newValue) => {
            setSearchString(newValue);
          }}
          value={searchString}
          placeholder="search"
        />
      </div>
      {resourcesState.data && resourcesState.data.length ? (
        <Link to="/library/resources" className={classes.resourcesButton}>
          <p className={classes.resourcesButtonText}>Extra Resources</p>
        </Link>
      ) : null}

      <div className={classes.main}>
        <div className={classes.sort}>
          <SortItem
            disabled={state.inProgress}
            onClick={() => {
              if (sort === SortOrder.nameForward) {
                setSort(SortOrder.nameReverse);
              } else {
                setSort(SortOrder.nameForward);
              }
            }}
            name="Deck"
          />
          <div className={classes.hintRoot}>
            <div
              role="button"
              className={classes.hintButton}
              onClick={() => {
                setNotification(
                  'TKL stands for True Knowledge Level™. This is Blank Slate’s unique analytic that estimates how well you know all of your material in this moment. In other words, if you were to take a pop quiz on all of your material right now, your TKL is the score you would be predicted to achieve.',
                );
              }}>
              <img
                className={classes.hintImage}
                alt="hint"
                src={RoundExclamationMark}
              />
            </div>

            <SortItem
              disabled={state.inProgress}
              onClick={() => {
                if (sort === SortOrder.tklForward) {
                  setSort(SortOrder.tklReverse);
                } else {
                  setSort(SortOrder.tklForward);
                }
              }}
              name="TKL"
            />
          </div>
        </div>
        {state.inProgress ? (
          <LoadingFallback />
        ) : (
          <div className={classes.items}>
            {sortedDecks.length === 0 ? (
              <NoData />
            ) : (
              sortedDecks.map((deck) => {
                return (
                  <Link
                    key={deck.deckId}
                    className={classes.itemLink}
                    to={`/library/deck/${deck.deckId}`}>
                    <div className={classes.itemContainer}>
                      <div className={classes.itemText}>
                        <p className={classes.paragraph}>{deck.deckName}</p>
                      </div>
                      <p className={classes.itemText}>
                        {deck.tkl === null ? 'N/A' : `${deck.tkl}%`}
                      </p>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};
