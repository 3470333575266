import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CorrectnessMark from './CorrectnessMark';
import CorrectnessMessage from './CorrectnessMessage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    maxHeight: '200px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
}));

export interface CardResultExclamationProps {
  isCorrect: boolean;
}

const CardResultExclamation: React.FC<CardResultExclamationProps> = ({
  isCorrect,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CorrectnessMark isCorrect={isCorrect} />
      <CorrectnessMessage isCorrect={isCorrect} />
    </div>
  );
};

export default CardResultExclamation;
