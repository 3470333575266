import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../styles/pages/Study.module.css';
import {useNavigate} from 'react-router-dom';
import {CardHtml} from '../model/domain/CardHtml';
import {debug} from '../util/logger';
import CardFront from '../components/card/CardFront';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import CardBack from '../components/card/CardBack';
import CardService from '../service/CardService';
import {AnswerEnum} from '../model/domain/enum/AnswerEnum';
import {Stat} from '../model/domain/Stat';
import {BSTaskFromIO, executeTask} from '../util/bs-fp';
import StudyService from '../service/StudyService';
import {NextStep} from '../model/domain/NextStep';
import {
  nextStepCounterState,
  nextStepState,
  nextStudyStatState,
  useUpdateStepState,
} from '../model/state/nextStepState';
import {useRecoilState, useRecoilValue} from 'recoil';
import AppConfig from '../AppConfig';
import LineProgressBar from '../components/progress/LineProgressBar';
import StatService from '../service/StatService';
import {AnimationTypeEnum} from '../model/domain/enum/AnimationTypeEnum';
import CardFeedback from '../components/card/CardFeedback';
import {userState} from '../model/state/userState';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';

interface StudyProps {}

const Study: React.FC<StudyProps> = ({}) => {
  let navigate = useNavigate();

  const nextStudyStat = useRecoilValue(nextStudyStatState);

  const [nextStep] = useRecoilState(nextStepState);
  const setNextStep = useUpdateStepState();

  const nextStepCounter = useRecoilValue(nextStepCounterState);

  useEffect(() => {
    document.title = "Today's Cards – Blank Slate";
  }, []);

  const card = useMemo<O.Option<CardHtml>>(
    () =>
      pipe(
        nextStep,
        O.chain((s: NextStep) => s.c),
      ),
    [nextStep],
  );

  const isSurvey = useMemo(
    () =>
      pipe(
        card,
        O.map((c: CardHtml) => CardService.isSurvey(c)),
        O.getOrElse(() => false),
      ),
    [card],
  );

  const isTextMode = useMemo(
    () =>
      pipe(
        card,
        O.map((c: CardHtml) => CardService.isTextMode(c)),
        O.getOrElse(() => false),
      ),
    [card],
  );

  const feedbackText = useMemo(
    () =>
      pipe(
        card,
        O.chain((c: CardHtml) => c.bComment),
        O.getOrElse(() => ''),
      ),
    [card],
  );

  const [cardBackWasShown, setCardBackWasShown] = useState(false);
  const [answerStat, setAnswerStat] = useState<O.Option<Stat>>(O.none);
  const user = useRecoilValue(userState);

  const [isFront, setIsFront] = useState(true);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [startTime, setStartTime] = useState<O.Option<number>>(O.none);
  const [totalAnswerTime, setTotalAnswerTime] = useState(0);
  const [rawAnswers, setRawAnswers] = useState<O.Option<string[]>>(O.none);
  const [animationType, setAnimationType] = useState<
    O.Option<AnimationTypeEnum>
  >(O.none);

  const [inProgress, setInProgress] = useState(false);

  const timer = useRef<O.Option<NodeJS.Timeout>>(O.none);

  const reviewProgress = useMemo(
    () => StatService.getProgressOrZero(nextStep),
    [nextStep],
  );

  const checkAnswerIsCorrect = useCallback(
    (card: O.Option<CardHtml>, rawAnswers: O.Option<string[]>) => {
      return pipe(
        card,
        O.chain((c: CardHtml) =>
          CardService.checkAnswerCorrectness(c, rawAnswers),
        ),
      );
    },
    [],
  );

  const answerIsCorrect = useMemo(
    () => checkAnswerIsCorrect(card, rawAnswers),
    [checkAnswerIsCorrect, card, rawAnswers],
  );

  const hasFeedback = useMemo(() => {
    let isCorrect = O.getOrElse(() => false)(answerIsCorrect);
    return (
      feedbackText.length > 0 &&
      (!isCorrect || O.toNullable(user)?.partner.showFeedbackAlways)
    );
  }, [feedbackText, answerIsCorrect, user]);

  const computeTotalAnswerTime = useCallback(
    () =>
      pipe(
        startTime,
        O.fold(
          () => 0,
          (startDt: number) => Date.now() - startDt,
        ),
      ),
    [startTime],
  );

  const runAnswerCountdownTimer = useCallback(() => {
    const duration = AppConfig.answerTimeout;
    setIsTimeOut(false);
    const t = setTimeout(() => {
      debug('ANSWER TIMEOUT!!!!!!');
      setIsTimeOut(true);
      timer.current = O.none;
    }, duration);
    debug(`RUN Answer countdown timer ${duration}ms:`, t);
    timer.current = O.some(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsTimeOut, timer]);

  const stopAnswerCountdownTimer = useCallback(() => {
    pipe(
      timer.current,
      O.map((t) => {
        debug('STOP: Answer countdown timer:', timer);
        clearTimeout(t);
        timer.current = O.none;
      }),
    );
  }, [timer]);

  const showAnimationIfNeeded = useCallback(
    (correct: O.Option<boolean>) => {
      debug('showAnimationIfNeeded, theOutcomeIsCorrect=', answerIsCorrect);
      setAnimationType(
        pipe(
          correct,
          O.map((v: boolean) =>
            v ? AnimationTypeEnum.Positive : AnimationTypeEnum.Negative,
          ),
        ),
      );
    },
    [answerIsCorrect],
  );

  const onSendAnswerError = useCallback((e: BSError): void => {
    debug(e);
    setInProgress(false);
  }, []);

  const navigateToCongratulationScreen = useCallback(
    () => navigate('/congratulation'),
    [navigate],
  );

  const onSendAnswerOk = useCallback(
    (nextStep: NextStep): void => {
      setInProgress(false);
      setNextStep(O.some(nextStep));
      if (O.isSome(nextStep.c) &&
        ((O.toUndefined(answerStat)?.mode !== nextStep.mode && nextStep.mode !== 'Review') ||
          (O.toUndefined(answerStat)?.mode === nextStep.mode) ||
          (O.toUndefined(answerStat)?.mode === undefined && nextStep.mode === 'Review')
        )
      ) {
        setIsFront(true);
        setStartTime(O.some(Date.now()));
        setCardBackWasShown(false);
      } else {
        navigateToCongratulationScreen();
      }
    },
    [navigateToCongratulationScreen, setNextStep, answerStat],
  );

  const setToast = useError(true);

  const sendAnswerTask: (stat: Stat) => T.Task<void> = useCallback(
    (stat: Stat) =>
      pipe(
        BSTaskFromIO(() => setInProgress(true)),
        TE.chain(() => StudyService.nextStep(O.some(stat), stat.mode === 'Review' ? 'skipSurvey' : undefined)),
        TE.mapLeft(setToast),
        T.map(E.fold(onSendAnswerError, onSendAnswerOk)),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setInProgress, onSendAnswerError, onSendAnswerOk],
  );

  const sendAnswerAndGetNextStep = useCallback(
    async (stat: Stat) => {
      const task = sendAnswerTask(stat);
      await executeTask(task);
    },
    [sendAnswerTask],
  );

  const onBackAnswer = useCallback(
    (c: CardHtml, mode: string) => async (answer?: AnswerEnum) => {
      const stat = {
        id: 0,
        uId: 0,
        dId: c.dId,
        cId: c.cId,
        dt: 0,
        tta: totalAnswerTime,
        ans: answer || AnswerEnum.Easy,
        rawAnswers: rawAnswers,
        mode: mode,
      };

      debug('Stat', stat);
      if (hasFeedback) {
        setAnswerStat(O.some(stat));
        setCardBackWasShown(true);
      } else {
        setAnswerStat(O.some(stat));
        await sendAnswerAndGetNextStep(stat);
      }
    },
    [
      totalAnswerTime,
      rawAnswers,
      hasFeedback,
      setAnswerStat,
      setCardBackWasShown,
      sendAnswerAndGetNextStep,
    ],
  );

  const onNextFeedback = useCallback(async () => {
    debug('onNextFeedbackStat', answerStat);
    const promise = pipe(
      answerStat,
      O.map((stat: Stat) => sendAnswerAndGetNextStep(stat)),
      O.getOrElse(() => Promise.resolve()),
    );
    await promise;
  }, [answerStat, sendAnswerAndGetNextStep]);

  const handleSurveyAnswer = useCallback(
    async (totalAnswerTime: number, rawAnswers: string[]) => {
      debug('handleSurveyAnswer', totalAnswerTime, rawAnswers);
      const promise = pipe(
        card,
        O.map(
          (c) =>
            ({
              id: 0,
              uId: 0,
              dId: c.dId,
              cId: c.cId,
              dt: 0,
              tta: totalAnswerTime,
              ans: 33, // survey answer
              rawAnswers: O.some(rawAnswers),
              mode: 'Survey',
            } as Stat),
        ),
        O.map((stat: Stat) => sendAnswerAndGetNextStep(stat)),
        O.getOrElse(() => Promise.resolve()),
      );
      await promise;
    },
    [card, sendAnswerAndGetNextStep],
  );

  const onFrontAnswer = useCallback(
    (answers: string[]) => {
      stopAnswerCountdownTimer();
      debug('isTimeOut', isTimeOut);
      const totalAnswerTime = computeTotalAnswerTime();
      setTotalAnswerTime(totalAnswerTime);
      if (isSurvey) {
        handleSurveyAnswer(totalAnswerTime, answers);
      } else {
        setRawAnswers(O.some(answers));
        showAnimationIfNeeded(checkAnswerIsCorrect(card, O.some(answers)));
        setIsFront(false);
      }
    },
    [
      card,
      checkAnswerIsCorrect,
      showAnimationIfNeeded,
      stopAnswerCountdownTimer,
      isTimeOut,
      setRawAnswers,
      setIsFront,
      setTotalAnswerTime,
      computeTotalAnswerTime,
      isSurvey,
      handleSurveyAnswer,
    ],
  );

  const frontContent = useMemo(
    () =>
      pipe(
        card,
        O.map<CardHtml, any>((c: CardHtml) => (
          <CardFront
            isLoading={inProgress}
            cardCounter={nextStepCounter}
            card={c}
            onAnswer={onFrontAnswer}
          />
        )),
        O.getOrElse(() => null),
      ),
    [card, onFrontAnswer, nextStepCounter, inProgress],
  );

  const backContent = useMemo(
    () =>
      pipe(
        nextStep,
        O.map<NextStep, any>((step: NextStep) => {

          return pipe(step.c, O.map<CardHtml, any>((c: CardHtml) => (
              <CardBack
                isCorrect={checkAnswerIsCorrect(step.c, rawAnswers)}
                isAnswerTimeExceeded={isTextMode ? false : isTimeOut}
                card={c}
                onAnswer={onBackAnswer(c, step.mode)}
                nextQuestionButtonText={'Next'}
              />
            )
          ), O.getOrElse(() => null))
        }),
        O.getOrElse(() => null),
      ),
    [
      nextStep,
      checkAnswerIsCorrect,
      isTimeOut,
      onBackAnswer,
      rawAnswers,
      isTextMode,
    ],
  );

  const feedbackContent = useMemo(
    () => <CardFeedback text={feedbackText} onNext={onNextFeedback} />,
    [feedbackText, onNextFeedback],
  );

  useEffect(() => {
    runAnswerCountdownTimer();
    setStartTime(O.some(Date.now()));
    return () => {
      stopAnswerCountdownTimer();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return (
    <>
      <div className={styles.content}>
        {isFront
          ? frontContent
          : hasFeedback && cardBackWasShown
          ? feedbackContent
          : backContent}
      </div>
      <div className={styles.footer}>
        <LineProgressBar
          steps={reviewProgress.total}
          current={reviewProgress.reviewed}
        />
      </div>
    </>
  );
};

export default Study;
