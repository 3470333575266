import React, {useMemo} from 'react';
import markCorrectImage from '../../assets/images/mark-correct.svg';
import markIncorrectImage from '../../assets/images/mark-incorrect.png';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export interface CorrectnessMarkProps {
  isCorrect: boolean;
}

const CorrectnessMark: React.FC<CorrectnessMarkProps> = ({isCorrect}) => {
  const classes = useStyles();

  const imageSource = useMemo(
    () => (isCorrect ? markCorrectImage : markIncorrectImage),
    [isCorrect],
  );
  return (
    <div className={classes.root}>
      <img src={imageSource} alt={isCorrect ? 'Correct' : 'Incorrect'} />
    </div>
  );
};

export default CorrectnessMark;
