import {ReviewAdditionalCards, StudyStat, zeroReviewAdditionalCards} from '../model/domain/StudyStat';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import {debug} from '../util/logger';
import {
  ReviewProgress,
  zeroReviewProgress,
} from '../model/domain/ReviewProgress';
import {NextStep, NextStepProgressWithReview} from '../model/domain/NextStep';

const numberOfCardsToReviewToday = (stat: O.Option<NextStepProgressWithReview>): number =>
  pipe(
    stat,
    O.map((s) =>
      s.progress.Main.total - s.progress.Main.done),
    O.getOrElse(() => 0),
  );

const computeProgress = (step: NextStep): ReviewProgress => {
  const block = step.progress[step.mode as keyof typeof step.progress];

    const reviewed = block.done;
    const total = block.total;
    return {reviewed, total};
};

const reviewStatus = (stat: ReviewAdditionalCards) => {
  const mode = stat.mode;
  const message = stat.msg;
  return {mode, message};
};

const getProgressOrZero = (step: O.Option<NextStep>): ReviewProgress =>
  pipe(
    step,
    O.map(computeProgress),
    O.getOrElse(() => zeroReviewProgress),
  );

const hasAssignedCards = (stat: O.Option<StudyStat>): boolean =>
  pipe(
    stat,
    O.map((s: StudyStat) => s.hasAssignedCards),
    O.getOrElse<boolean>(() => false),
  );

const getAdditionalCards = (stat: O.Option<ReviewAdditionalCards>) =>
  pipe(
    stat,
    O.map(reviewStatus),
    O.getOrElse(() => zeroReviewAdditionalCards),
  );

const getReviewCardNumber = (stat: O.Option<NextStepProgressWithReview>): number =>
  pipe(
    stat,
    O.map((s) =>
      s.progress.Review.total - s.progress.Review.done),
    O.getOrElse(() => 0),
  );

export default {
  numberOfCardsToReviewToday,
  getProgressOrZero,
  hasAssignedCards,
  getAdditionalCards,
  getReviewCardNumber,
};
