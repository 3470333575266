import { DeckEntry } from '../../model/domain/Deck';
import { DeckTO } from '../to/DeckTO';

const toDomain = (to: DeckTO): DeckEntry[] =>
  (to.map(deckEntry => ({
    deckId: deckEntry.deckId,
    deckName: deckEntry.deckName,
    tkl: deckEntry.tkl
  })) as DeckEntry[]);

export default {
  toDomain,
};
