import * as io from 'io-ts';
import {responseDecoder} from './TOEncoderDecoder';
import {nullableString} from './commonTypes';

export const NotificationTODecoder = io.type({
  message: nullableString,
});

export type NotificationTO = io.TypeOf<typeof NotificationTODecoder>;

export const notificationTOResponseDecoder = responseDecoder<NotificationTO>(
  NotificationTODecoder,
);
