import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '60px',
    height: '60px',
    borderRadius: '30px',
  },
  text: {
    fontWeight: 200,
    fontSize: '22px',
    color: theme.palette.primary.dark,
  },
}));

interface NextButtonProps {
  onClick: () => void;
  text?: string;
}

const NextButton: React.FC<NextButtonProps> = ({onClick, text}) => {
  const classes = useStyles();
  return (
    <Button color="primary" className={classes.button} onClick={onClick}>
      <span className={classes.text}>{text || 'Skip'}</span>
    </Button>
  );
};

export default NextButton;
