import React, {CSSProperties, ReactElement} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  title: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 200,
    fontSize: 14,
    color: theme.palette.extended.preferencesSectionTitle,
    paddingLeft: '16px',
  },
}));

export interface PreferencesInputContainerProps {
  title: string;
  children: ReactElement;
  titleStyle?: CSSProperties;
}

const PreferencesInputContainer: React.FC<PreferencesInputContainerProps> = ({
  title,
  children,
  titleStyle,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title} style={titleStyle}>
        {title}
      </div>
      {children}
    </div>
  );
};

export default PreferencesInputContainer;
