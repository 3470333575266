import * as t from 'io-ts';
import {flow} from 'fp-ts/function';
import {pipe} from 'fp-ts/lib/function';
import {failure} from 'io-ts/PathReporter';
import * as TE from 'fp-ts/TaskEither';
import {AxiosResponse} from 'axios';
import {BSTask} from '../../model/types';
import * as E from 'fp-ts/lib/Either';
import {BSError, BSErrorType} from '../../model/error/BSError';

const TODecodeError = {
  type: BSErrorType.InvalidResponseFormatError,
  userFriendlyTitle: "Server error",
  userFriendlyExplanation: "Invalid data received"
}

const TOEncodeError = {
  type: BSErrorType.InvalidResponseFormatError,
  userFriendlyTitle: "Server error",
  userFriendlyExplanation: "Invalid data sent"
}

export const encode = <A>(
  a: A,
  encoder: t.Encoder<A, unknown>,
): TE.TaskEither<BSError, unknown> =>
  pipe(
    TE.fromIO(() => encoder.encode(a)),
    TE.mapLeft((e: unknown) => TOEncodeError),
  );

export const decodeWith = <A>(decoder: t.Decoder<unknown, A>) =>
  flow(
    decoder.decode,
    E.mapLeft((errors: t.Errors): BSError => TODecodeError
    ),
    TE.fromEither,
  );

export const responseDecoder = <A>(decoder: t.Decoder<unknown, A>) => (
  response: BSTask<AxiosResponse<unknown>>,
) =>
  pipe(
    response,
    TE.map((x: AxiosResponse<unknown>) => x.data),
    TE.chain<BSError, unknown, A>(decodeWith(decoder)),
  );
