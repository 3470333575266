import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../../AppTheme';
import {ButtonBadge} from './ButtonBadge';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    width: '229px',
    height: '48px',
    borderRadius: '12px',
    backgroundColor: theme.palette.extended.cardActionButtonColor,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.extended.cardActionButtonHoverColor,
    },
    '&:active': {
      backgroundColor: theme.palette.extended.cardActionButtonHoverColor,
    },
    fontFamily: 'RobotoFlex',
    fontWeight: 700,
    fontSize: '20px',
    color: theme.palette.secondary.main,
  },
  buttonInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  iconContainer: {
    right: 0,
    position: 'absolute',
    paddingRight: '24px',
  },
}));

export interface NextQuestionButtonProps {
  onClick: () => void;
  text: string;
}

const NextQuestionButton: React.FC<NextQuestionButtonProps> = ({
  onClick,
  text,
}) => {
  const classes = useStyles();
  return (
    <Button className={classes.root} onClick={onClick}>
      <div className={classes.buttonInnerContainer}>{text}</div>
      <ButtonBadge text="Space" />
    </Button>
  );
};
export default NextQuestionButton;
