import {User} from '../model/domain/User';
import {BSTask} from '../model/types';
import {pipe} from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import UserApi from '../api/UserApi';
import {BSNotification} from '../model/domain/BSNotification';
import { TklStat } from '../model/domain/TklStat';

const updateSetting = (u: User): BSTask<void> => {
  return pipe(UserApi.updateSettings(u));
};
const setLibraryTourShown = (): BSTask<void> => {
  return pipe(UserApi.setLibraryTourShown());
};
const getNotification = (): BSTask<BSNotification> => {
  return pipe(UserApi.greeting());
};
const removeCurrentUser = (): BSTask<void> => {
  return pipe(UserApi.removeUser());
};
const getTKLStat = (): BSTask<TklStat> => {
  return pipe(
    UserApi.getTKLStat()
  );
};

export default {
  updateSetting,
  setLibraryTourShown,
  getNotification,
  getTKLStat,
  removeCurrentUser
};
