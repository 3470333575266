import './App.css';
import React from 'react';
import Layout from './pages/Layout';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import {greyTheme} from './AppTheme';
import {RecoilRoot} from 'recoil';
import {HashRouter} from 'react-router-dom';
import {Agreement} from './components/Agreement';
import {SideMenu} from './components/SideMenu';
import styles from './styles/pages/Layout.module.css';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <HashRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={greyTheme}>
            <CssBaseline />
            <Agreement />
            <SideMenu>
              <Layout />
            </SideMenu>
          </ThemeProvider>
        </StyledEngineProvider>
      </HashRouter>
    </RecoilRoot>
  );
};

export default App;
